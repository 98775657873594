import React, { createContext, useReducer } from "react";
import AppReducer from "./AppReducer";
import axios from "axios";

// Initial State
const initialState = {
  configs: [],
  resumes: [],
  competences: [],
  projects: [],
  error: {
    value: null,
    msg: null
  },
  loading: true,
  flag: 0,
  user: {
    token: undefined,
    userinfo: undefined
  }
};

// Create Context
export const GlobalContext = createContext(initialState);

// Provider component children sont les elements enveloppes
export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  /**Configs */
  async function getConfigs() {
    try {
      const res = await axios.get("/api/v1/configs");
      dispatch({
        type: "GET_CONFIGS",
        payload: res.data.data
      });
    } catch (err) {
      dispatch({
        type: "CONFIGS_ERROR",
        payload: err.response.data.error
      });
    }
  }

  /**COMPETENCES */
  async function getCompetences() {
    try {
      const res = await axios.get("/api/v1/competences");
      dispatch({
        type: "GET_COMPETENCES",
        payload: res.data.data
      });
    } catch (err) {
      dispatch({
        type: "COMPETENCES_ERROR",
        payload: err
      });
    }
  }

  async function postCompetence(competence) {
    let token = localStorage.getItem("auth-kobura");
    if (token === null) return;

    try {
      const competenceRes = await axios.post(
        "/api/v1/competences",
        competence,
        {
          headers: {
            "x-auth-token": token
          }
        }
      );
      dispatch({
        type: "POST_COMPETENCE",
        payload: { competenceRes }
      });
    } catch (err) {
      dispatch({
        type: "POST_COMPETENCE_ERROR",
        payload: err
      });
    }
  }

  async function putCompetence(competence) {
    let token = localStorage.getItem("auth-kobura");
    if (token === null) return;

    const competenceRes = await axios.put(
      `/api/v1/competences/${competence._id}`,
      competence,
      {
        headers: {
          "x-auth-token": token
        }
      }
    );
    dispatch({
      type: "PUT_COMPETENCE",
      payload: { competenceRes }
    });
  }

  async function deleteCompetence(id) {
    let token = localStorage.getItem("auth-kobura");
    if (token === null) return;

    try {
      const res = await axios.delete(`/api/v1/competences/${id}`, {
        headers: {
          "x-auth-token": token
        }
      });
      dispatch({
        type: "DELETE_COMPETENCE",
        payload: res.data.data
      });
    } catch (err) {
      dispatch({
        type: "DELETE_COMPETENCE_ERROR",
        payload: err
      });
    }
  }

  /**FLAG */
  function changeFlag() {
    dispatch({
      type: "CHANGE_FLAG",
      payload: state.flag
    });
  }

  /** USER */
  async function setUser() {
    let token = localStorage.getItem("auth-kobura");
    let userinfo = undefined;
    if (token === null) {
      localStorage.setItem("auth-kobura", "");
      token = "";
    }
    const tokenRes = await axios.post("/api/v1/users/valid", null, {
      headers: {
        "x-auth-token": token
      }
    });
    if (tokenRes.data) {
      const userRes = await axios.get("/api/v1/users/user", {
        headers: {
          "x-auth-token": token
        }
      });
      userinfo = userRes.data;
    }
    dispatch({
      type: "SET_USER",
      payload: { token, userinfo }
    });
  }

  /* RESUMES */
  async function getResumes() {
    try {
      const res = await axios.get("/api/v1/resumes");
      dispatch({
        type: "GET_RESUMES",
        payload: res.data.data
      });
    } catch (err) {
      dispatch({
        type: "RESUMES_ERROR",
        payload: err.response.data.error
      });
    }
  }

  function loadingTrue() {
    dispatch({
      type: "LOADING_TRUE"
    });
  }

  async function postResume(resume) {
    let token = localStorage.getItem("auth-kobura");
    if (token === null) return;
    const resumeRes = await axios.post("/api/v1/resumes", resume, {
      headers: {
        "x-auth-token": token
      }
    });
    dispatch({
      type: "POST_RESUME",
      payload: { resumeRes }
    });
  }

  async function putResume(resume) {
    let token = localStorage.getItem("auth-kobura");
    if (token === null) return;
    // console.log("RESUME", resume);
    const resumeRes = await axios.put(`/api/v1/resumes/${resume._id}`, resume, {
      headers: {
        "x-auth-token": token
      }
    });
    dispatch({
      type: "PUT_RESUME",
      payload: { resumeRes }
    });
  }

  async function deleteResume(id) {
    let token = localStorage.getItem("auth-kobura");
    if (token === null) return;

    try {
      const res = await axios.delete(`/api/v1/resumes/${id}`, {
        headers: {
          "x-auth-token": token
        }
      });
      dispatch({
        type: "DELETE_RESUME",
        payload: res.data.data
      });
    } catch (err) {
      dispatch({
        type: "DELETE_RESUME_ERROR",
        payload: err.response.data.error
      });
    }
  }

  /* PROJECTS */
  async function getProjects() {
    try {
      const res = await axios.get("/api/v1/projects");
      dispatch({
        type: "GET_PROJECTS",
        payload: res.data.data
      });
    } catch (err) {
      dispatch({
        type: "PROJECTS_ERROR",
        payload: err.response.data.error
      });
    }
  }

  async function postProject(project) {
    let token = localStorage.getItem("auth-kobura");
    if (token === null) return;

    try {
      const projectRes = await axios.post("/api/v1/projects", project, {
        headers: {
          "x-auth-token": token
        }
      });
      dispatch({
        type: "POST_PROJECT",
        payload: { projectRes }
      });
    } catch (err) {
      // console.log("stateErr", err.response.data.error);
      dispatch({
        type: "PROJECT_ERROR",
        payload: err.response.data.error
      });
    }
  }

  async function putProject(project) {
    let token = localStorage.getItem("auth-kobura");
    if (token === null) return;

    try {
      const projectRes = await axios.put(
        `/api/v1/projects/${project._id}`,
        project,
        {
          headers: {
            "x-auth-token": token
          }
        }
      );
      dispatch({
        type: "PUT_PROJECT",
        payload: { projectRes }
      });
    } catch (err) {
      dispatch({
        type: "PROJECT_ERROR",
        payload: err.response.data.error.message
      });
    }
  }

  async function deleteProject(id) {
    let token = localStorage.getItem("auth-kobura");
    if (token === null) return;

    try {
      const res = await axios.delete(`/api/v1/projects/${id}`, {
        headers: {
          "x-auth-token": token
        }
      });
      dispatch({
        type: "DELETE_PROJECT",
        payload: res.data.data
      });
    } catch (err) {
      dispatch({
        type: "PROJECT_ERROR",
        payload: err.response.data.error
      });
    }
  }

  /* E-mail */
  async function postEmail(email) {
    try {
      const emailRes = await axios.post("/api/v1/email-me", email);
      dispatch({
        type: "POST_EMAIL",
        payload: { emailRes }
      });
    } catch (err) {
      dispatch({
        type: "EMAIL_ERROR",
        payload: err.response.data.error ?? err.response.data
      });
    }
  }

  async function setErrorNull() {
    dispatch({
      type: "SET_ERROR_NULL",
      payload: null
    });
  }
  /* End */

  return (
    <GlobalContext.Provider
      value={{
        user: state.user,
        configs: state.configs,
        resumes: state.resumes,
        competences: state.competences,
        projects: state.projects,
        setUser,
        getConfigs,
        getResumes,
        postResume,
        putResume,
        deleteResume,
        getCompetences,
        postCompetence,
        putCompetence,
        deleteCompetence,
        getProjects,
        postProject,
        putProject,
        deleteProject,
        postEmail,
        error: state.error,
        loading: state.loading,
        loadingTrue,
        flag: state.flag,
        changeFlag,
        setErrorNull
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
