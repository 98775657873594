import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

const BtnToTop = () => {
  const [showScroll, setShowScroll] = useState(false);
  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };
  window.addEventListener("scroll", checkScrollTop);
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const useStyles = makeStyles(theme => ({
    scrollTop: {
      position: "fixed",
      bottom: "20px",
      right: "10px",
      zIndex: 1000,
      cursor: "pointer",
      animation: `$fadeIn 4s`,
      transition: "opacity 1s",
      opacity: 0.6,
      "&:hover": {
        opacity: 1,
        scale: 1.075
      }
    },
    "@keyframes fadeIn": {
      "0%": {
        opacity: 0
      },
      "100%": {
        opacity: 0.6
      }
    }
  }));
  const classes = useStyles();
  return (
    <div
      className={classes.scrollTop}
      onClick={scrollTop}
      style={{ height: 40, display: showScroll ? "flex" : "none" }}
    >
      <svg width="32" height="32" viewBox="0 0 100 100">
        <path
          fill="tan"
          d="m50 0c-13.262 0-25.98 5.2695-35.355 14.645s-14.645 22.094-14.645 35.355 5.2695 25.98 14.645 35.355 22.094 14.645 35.355 14.645 25.98-5.2695 35.355-14.645 14.645-22.094 14.645-35.355-5.2695-25.98-14.645-35.355-22.094-14.645-35.355-14.645zm20.832 62.5-20.832-22.457-20.625 22.457c-1.207 0.74219-2.7656 0.57812-3.7891-0.39844-1.0273-0.98047-1.2695-2.5273-0.58594-3.7695l22.918-25c0.60156-0.61328 1.4297-0.96094 2.2891-0.96094 0.86328 0 1.6914 0.34766 2.293 0.96094l22.918 25c0.88672 1.2891 0.6875 3.0352-0.47266 4.0898-1.1562 1.0508-2.9141 1.0859-4.1133 0.078125z"
        ></path>
      </svg>
    </div>
  );
};

export default BtnToTop;
