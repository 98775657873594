import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Header from "./Header";
import Connect from "./Connect";
import Particles from "react-particles-js";
import PublicIcon from "@material-ui/icons/Public";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import Ticker from "react-ticker";
import { makeStyles } from "@material-ui/core/styles";
import Axios from "axios";

const useStyles = makeStyles(theme => ({
  particlesCanva: {
    // border: "2px solid orange",
    position: "absolute",
    clipPath: "polygon(3% 0, 100% 0, 97% 100%, 0 87%)"
  },
  outerDiv: {
    position: "absolute",
    [theme.breakpoints.down("xs")]: {
      // border: "2px solid yellow",
      position: "absolute",
      top: "14%",
      // left: "10%",
      left: "calc(30vw - 70px )",
      right: "1%",
      height: "50%"
    },
    [theme.breakpoints.up("sm")]: {
      // border: "2px solid blue",
      position: "absolute",
      top: "15%",
      // width: "75%",
      width: "70%",
      // left: "22%",
      left: "calc(60vw - 224px )",
      maxWidth: "500px",
      //right: "3%",
      height: "48%"
    },
    [theme.breakpoints.up("md")]: {
      // border: "2px solid red",
      position: "absolute",
      top: "15%",
      // top: "calc(100% - 200px)",
      left: "35%",
      width: "52%",
      height: "50%",
      maxWidth: "700px"
    },
    [theme.breakpoints.up("lg")]: {
      // border: "2px solid green",
      position: "absolute",
      top: "9%",
      left: "33%",
      width: "54%",
      height: "60%",
      maxWidth: "2000px"
    }
  },
  container: {
    clipPath: "polygon(3% 0, 100% 0, 97% 100%, 0 87%)",
    // border: "2px solid white",
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      clipPath: "polygon(3% 0, 100% 0, 100% 100%, 0 87%)"
    }
  },
  title: {
    color: "tomato",
    // fontFamily: "Lobster Two",
    fontFamily: "Courgette"
  },
  infos: {
    position: "absolute",
    color: "tan",
    width: "60%",
    right: "5%",
    top: "3%",
    transform: "rotate(0.1deg)",
    [theme.breakpoints.up("lg")]: {
      top: "7%"
    }
  },
  icon: {
    opacity: "0.5",
    position: "absolute",
    transform: "translate(-30px, -3px)"
  },
  tickers: {
    opacity: "0.4",
    color: "tan",
    fontSize: "0.9rem",
    whiteSpace: "nowrap"
  },
  power: {
    cursor: "pointer",
    position: "absolute",
    fontSize: "22px",
    color: "white",
    bottom: "-30px",
    right: "15%",
    transform: "rotate(2deg)",
    opacity: "0.5",
    "&:hover": {
      opacity: "0.7",
      transition: "opacity 500ms ease"
    },
    animation: `$btnIn 3000ms ${theme.transitions.easing.easeIn}`,
    [theme.breakpoints.down("xs")]: {
      right: "5%",
      bottom: "-20px",
      fontSize: "18px"
    }
  },
  powerDown: {
    color: "red",
    animation: `$btnIn 1000ms infinite`
  },
  "@keyframes btnIn": {
    "0%": {
      opacity: 0
    },
    "100%": {
      opacity: 0.5
    }
  }
}));

const Home = () => {
  const classes = useStyles();
  const [showForm, setShowForm] = useState(false);
  const [fail, setFail] = useState(false);
  const [allTicker, setAllTicker] = useState("");

  useEffect(() => {
    let tickerArr = [];
    const api = async () => {
      const tickers = await Axios.get("/api/v3/news");
      // const tickers = await Axios.get("/api/v1/projects");
      tickers.data.forEach(ticker => {
        tickerArr.push(ticker.name);
        // tickerArr.push(ticker.title);
      });
      tickerArr.push(
        " Discovery of The Most Beautiful Flower 🌺Carolina Spring Beauty🌺 🚀 "
      );
      // console.log("Data", tickerArr);
      setAllTicker(tickerArr);
    };
    api();
  }, []);

  return (
    <div>
      <Navbar showForm={showForm} setShowForm={setShowForm} />
      <div className={classes.outerDiv}>
        <div className={classes.container}>
          <div className={classes.infos}>
            <PublicIcon className={classes.icon} />
            <Ticker mode="smooth" speed={5}>
              {({ index }) => (
                <>
                  <p className={classes.tickers}>
                    {allTicker
                      ? allTicker.join("     🚀  ")
                      : "Hubble News ... Is Down 😭"}
                  </p>
                </>
              )}
            </Ticker>
          </div>
          <Particles
            canvasClassName={classes.particlesCanva}
            height="96%"
            params={{
              particles: {
                number: {
                  value: 10,
                  density: {
                    enable: false,
                    value_area: 700
                  }
                },
                line_linked: {
                  enable: false,
                  opacity: 0.01
                },
                shape: {
                  type: "star",
                  stroke: {
                    width: 1,
                    color: "tomato"
                  }
                },
                move: {
                  // direction: "right",
                  speed: 1
                },
                size: {
                  value: 3,
                  random: true,
                  anim: {
                    enable: true,
                    speed: 0.4,
                    size_min: 0.1,
                    sync: false
                  }
                },
                opacity: {
                  value: 0.3,
                  random: true,
                  anim: {
                    enable: true,
                    speed: 0.5,
                    opacity_min: 0.1,
                    sync: false
                  }
                }
              }
            }}
          />
          {(!showForm && <Header />) || (
            <Connect
              showForm={showForm}
              setShowForm={setShowForm}
              fail={fail}
              setFail={setFail}
            />
          )}
        </div>
        <PowerSettingsNewIcon
          className={`${classes.power} ${fail && classes.powerDown}`}
          onClick={() => {
            setShowForm(!showForm);
            setFail(false);
          }}
        />
      </div>
    </div>
  );
};

export default Home;
