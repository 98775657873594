import React, { useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalState";
import { makeStyles } from "@material-ui/core/styles";
import Navbar from "../Navbar";
import Particules from "../effects/Particules";
import personal from "../../assets/img/personal.jpg";
import cardFooter from "../../assets/img/dark-dot.png";
import {
  BottomNavigation,
  Box,
  CardActionArea,
  CardMedia,
  Grid
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  mainContainer: {
    height: "100%"
  },
  cardContainer: {
    margin: "4rem auto",
    maxWidth: 280,
    minWidht: 100
  },
  cardContent: {
    overflowY: "auto",
    background: "whitesmoke",
    height: "255px"
  },
  cardFooter: {
    background: "#222",
    backgroundImage: `url(${cardFooter})`,
    "& .MuiBottomNavigation": {
      minWidth: 0,
      maxWidth: 30
    },
    "& .MuiBottomNavigationAction-wrapper": {
      justifyContent: "center",
      width: "auto"
    },
    "& .MuiSvgIcon-root": {
      justifyContent: "center",

      fill: "tan",
      "&:hover": {
        fill: "tomato",
        fontSize: "1.8rem"
      }
    }
  },
  overlay: {
    position: "absolute",
    color: "white",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: "30px",
    textTransform: "uppercase",
    fontFamily: "Orbitron"
  }
}));

const Admin = () => {
  let history = useHistory();
  // Context
  const { user } = useContext(GlobalContext);
  useEffect(() => {
    if (user.userinfo === undefined) {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();

  const categories = [
    {
      id: "1",
      type: "Configuration",
      title: "Force de travail",
      desc: [
        "Dévouée et persévérante, je possède une grande capacité de travail (trois activités principales en parallèle).\n\n\n=>Évidemment ces cartes compétences seront remplies avec ce que tu veux mettre en avant 😁. Ainsi que les liens ci-dessous."
      ],
      link: "/admin/configuration"
    },
    {
      id: "2",
      type: "Résumé",
      title: "Force de travail",
      desc: [
        "Dévouée et persévérante, je possède une grande capacité de travail (trois activités principales en parallèle).\n\n\n=>Évidemment ces cartes compétences seront remplies avec ce que tu veux mettre en avant 😁. Ainsi que les liens ci-dessous."
      ],
      link: "/admin/resumes"
    },
    {
      id: "3",
      type: "Compétences",
      title: "Force de travail",
      desc: [
        "Dévouée et persévérante, je possède une grande capacité de travail (trois activités principales en parallèle).\n\n\n=>Évidemment ces cartes compétences seront remplies avec ce que tu veux mettre en avant 😁. Ainsi que les liens ci-dessous."
      ],
      link: "/admin/skills"
    },
    {
      id: "4",
      type: "Projets",
      title: "Force de travail",
      desc: ["Images : \n354x558"],
      link: "/admin/projects"
    }
  ];

  return (
    <>
      <Box component="div" className={classes.mainContainer}>
        <Navbar />
        <Particules />
        <Box
          component="div"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Typography
            gutterBottom
            variant="h3"
            style={{ color: "white", position: "relative" }}
          >
            ADMIN
          </Typography>
        </Box>
        <Grid container justify="center">
          {categories.map(category => (
            <Grid
              key={category.id}
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              style={{ zIndex: "1" }}
            >
              <Card className={classes.cardContainer}>
                <CardActionArea component={Link} to={category.link}>
                  <div style={{ position: "relative" }}>
                    <CardMedia
                      component="img"
                      alt="project1"
                      height="80"
                      image={personal}
                    />
                    <Typography variant="h4" className={classes.overlay}>
                      {category.type}
                    </Typography>
                  </div>
                  <CardContent className={classes.cardContent}>
                    <Typography
                      gutterBottom
                      variant="h5"
                      style={{ color: "black" }}
                    >
                      {category.title}
                    </Typography>
                    <Typography
                      style={{
                        height: "180px",
                        whiteSpace: "pre-line"
                      }}
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {category.desc}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <BottomNavigation
                  className={classes.cardFooter}
                  style={{
                    justifyContent: "center",
                    height: "40px"
                  }}
                ></BottomNavigation>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default Admin;
