import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../../context/GlobalState";
import { Link, useHistory } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { TextField, Typography, Button, Box } from "@material-ui/core";
import ReplyIcon from "@material-ui/icons/Reply";
import SaveIcon from "@material-ui/icons/Save";
import Navbar from "../Navbar";
import Particules from "../effects/Particules";
import gbFlag from "../../Gb.png";
import frFlag from "../../Fr.png";
import jpFlag from "../../Jp.png";
// import Flags from "./components/Flags";
import Axios from "axios";

const useStyles = makeStyles(theme => ({
  mainContainer: {
    // background: "#291e36",
    height: "100%"
  },
  form: {
    maxWidth: "800px",
    position: "relative",
    padding: "1rem",
    margin: "0 auto"
  },
  button: {
    marginTop: "1rem",
    color: "tomato",
    borderColor: "tomato",
    lineHeight: "1.2rem",
    alignItems: "self-end"
  },
  heading: {
    color: "tomato",
    padding: "3rem 0",
    textTransform: "uppercase"
  },
  opaci: {
    opacity: "0.5"
  }
}));

const InputField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "tomato"
    },
    "& label": {
      color: "tan"
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "tan"
      },
      "&:hover fieldset": {
        borderColor: "tan"
      },
      "&.Mui-focused fieldset": {
        borderColor: "tomato"
      }
    }
  }
})(TextField);

const Configuration = () => {
  const classes = useStyles();
  const [values, setValues] = useState({});
  const { user, loading, configs } = useContext(GlobalContext);
  let history = useHistory();
  const [curFlag, setCurFlag] = useState(0);

  useEffect(() => {
    if (!loading && user !== undefined) {
      if (user.userinfo === undefined) {
        history.push("/");
        // console.log(user);
      }
      setValues(configs);
      // console.log("setValues(configs)", configs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, user]);

  const isFormValid = () => {
    if (!values.fullname || !values.qualities.length !== 0 || !values.flag) {
      return false;
    } else {
      return true;
    }
  };

  const handleChange = name => e => {
    let newValues = [...values];
    if (e.target.id === "qualities") {
      newValues[curFlag][e.target.id] = e.target.value.split(",");
    } else {
      newValues[curFlag][e.target.id] = e.target.value;
    }
    setValues(newValues);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    // console.log(values);
    if (isFormValid()) {
      // console.log("Form invalid");
    } else {
      // console.log("Form ok");
    }
    try {
      const conf = {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": user.token
        }
      };
      await Axios.put(
        `/api/v1/configs/${values[curFlag]._id}`,
        values[curFlag],
        conf
      );
      // console.log(res);
      //setValues(res.data.data);
    } catch (err) {
      // console.log(err);
      return false;
    }
  };

  const handleFlag = e => {
    const btns = document.querySelectorAll(".btn-flag");
    btns.forEach(btn => {
      btn.classList.add(classes.opaci);
    });
    e.currentTarget.classList.remove(classes.opaci);
    setCurFlag(e.currentTarget.value);
  };
  // console.log(values);
  return (
    <>
      <Navbar />
      <Particules />
      {(loading && (
        <Box
          component="div"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Typography
            gutterBottom
            variant="h3"
            style={{ color: "white", position: "relative" }}
          >
            Loading...
          </Typography>
        </Box>
      )) || (
        <>
          <Box component="header" className={classes.mainContainer}>
            <Box
              component="div"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Typography
                gutterBottom
                variant="h3"
                style={{ color: "white", position: "relative" }}
              >
                ADMIN/Configuration
              </Typography>
            </Box>
            <Box
              component="form"
              className={classes.form}
              onSubmit={e => handleSubmit(e)}
            >
              {/* <Flags curFlag={curFlag} setCurFlag={setCurFlag} /> */}
              <Box component="div">
                <Button value={0} onClick={handleFlag} className="btn-flag">
                  <img src={frFlag} alt="fr"></img>
                </Button>
                <Button
                  value={1}
                  onClick={handleFlag}
                  className={`${classes.opaci} btn-flag`}
                >
                  <img src={gbFlag} alt="gb"></img>
                </Button>
                <Button
                  value={2}
                  onClick={handleFlag}
                  className={`${classes.opaci} btn-flag`}
                >
                  <img src={jpFlag} alt="jp"></img>
                </Button>
              </Box>
              <Box
                component="div"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "1rem"
                }}
              >
                <InputField
                  fullWidth={false}
                  label="Flag"
                  variant="outlined"
                  margin="dense"
                  size="medium"
                  className="inputContact"
                  id="flag"
                  type="text"
                  required={true}
                  onChange={handleChange()}
                  inputProps={{
                    style: { color: "white" },
                    onChange: handleChange(),
                    value: values[curFlag] ? values[curFlag].flag : ""
                  }}
                />
                <InputField
                  fullWidth={true}
                  label="FullName"
                  variant="outlined"
                  margin="dense"
                  size="medium"
                  className="inputContact"
                  id="fullname"
                  required={true}
                  inputProps={{
                    style: { color: "white" },
                    onChange: handleChange(),
                    value: values[curFlag] ? values[curFlag].fullname : "",
                    type: "text"
                  }}
                />
              </Box>
              <InputField
                fullWidth={true}
                label="Qualities"
                multiline
                rows={4}
                variant="outlined"
                margin="dense"
                size="medium"
                className="inputContact"
                id="qualities"
                required={true}
                inputProps={{
                  style: { color: "white", whiteSpace: "pre-line" },
                  onChange: handleChange(),
                  value: values[curFlag] ? values[curFlag].qualities : "",
                  type: "text",
                  autoComplete: "off"
                }}
              />
              <Box
                component="div"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "1rem"
                }}
              >
                <InputField
                  fullWidth={false}
                  label="Title"
                  variant="outlined"
                  margin="dense"
                  size="medium"
                  className="inputContact"
                  id="title"
                  type="text"
                  required={true}
                  inputProps={{
                    style: { color: "white" },
                    onChange: handleChange(),
                    value: values[curFlag] ? values[curFlag].title : ""
                  }}
                />
                <InputField
                  fullWidth={false}
                  label="Home"
                  variant="outlined"
                  margin="dense"
                  size="medium"
                  className="inputContact"
                  id="home"
                  type="text"
                  required={true}
                  inputProps={{
                    style: { color: "white" },
                    onChange: handleChange(),
                    value: values[curFlag] ? values[curFlag].home : ""
                  }}
                />
                <InputField
                  fullWidth={false}
                  label="Resume"
                  variant="outlined"
                  margin="dense"
                  size="medium"
                  className="inputContact"
                  id="resume"
                  type="text"
                  required={true}
                  inputProps={{
                    style: { color: "white" },
                    onChange: handleChange(),
                    value: values[curFlag] ? values[curFlag].resume : ""
                  }}
                />
              </Box>
              <Box
                component="div"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "1rem"
                }}
              >
                <InputField
                  fullWidth={false}
                  label="Skills"
                  variant="outlined"
                  margin="dense"
                  size="medium"
                  className="inputContact"
                  id="skills"
                  type="text"
                  required={true}
                  inputProps={{
                    style: { color: "white" },
                    onChange: handleChange(),
                    value: values[curFlag] ? values[curFlag].skills : ""
                  }}
                />
                <InputField
                  fullWidth={false}
                  label="Contact"
                  variant="outlined"
                  margin="dense"
                  size="medium"
                  className="inputContact"
                  id="contact"
                  type="text"
                  required={true}
                  inputProps={{
                    style: { color: "white" },
                    onChange: handleChange(),
                    value: values[curFlag] ? values[curFlag].contact : ""
                  }}
                />
                <InputField
                  fullWidth={false}
                  label="Connection"
                  variant="outlined"
                  margin="dense"
                  size="medium"
                  className="inputContact"
                  id="connection"
                  type="text"
                  required={true}
                  inputProps={{
                    style: { color: "white" },
                    onChange: handleChange(),
                    value: values[curFlag] ? values[curFlag].connection : ""
                  }}
                />
              </Box>
              <Box
                component="div"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "1rem"
                }}
              >
                <InputField
                  fullWidth={false}
                  label="Contact-me"
                  variant="outlined"
                  margin="dense"
                  size="medium"
                  className="inputContact"
                  id="contactme"
                  type="text"
                  required={true}
                  inputProps={{
                    style: { color: "white" },
                    onChange: handleChange(),
                    value: values[curFlag] ? values[curFlag].contactme : ""
                  }}
                />
                <InputField
                  fullWidth={false}
                  label="Name"
                  variant="outlined"
                  margin="dense"
                  size="medium"
                  className="inputContact"
                  id="name"
                  type="text"
                  required={true}
                  inputProps={{
                    style: { color: "white" },
                    onChange: handleChange(),
                    value: values[curFlag] ? values[curFlag].name : ""
                  }}
                />
                <InputField
                  fullWidth={false}
                  label="Email"
                  variant="outlined"
                  margin="dense"
                  size="medium"
                  className="inputContact"
                  id="email"
                  type="text"
                  required={true}
                  inputProps={{
                    style: { color: "white" },
                    onChange: handleChange(),
                    value: values[curFlag] ? values[curFlag].email : ""
                  }}
                />
              </Box>
              <Box
                component="div"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "1rem"
                }}
              >
                <InputField
                  fullWidth={false}
                  label="Company"
                  variant="outlined"
                  margin="dense"
                  size="medium"
                  className="inputContact"
                  id="company"
                  type="text"
                  required={true}
                  inputProps={{
                    style: { color: "white" },
                    onChange: handleChange(),
                    value: values[curFlag] ? values[curFlag].company : ""
                  }}
                />
                <InputField
                  fullWidth={false}
                  label="Message"
                  variant="outlined"
                  margin="dense"
                  size="medium"
                  className="inputContact"
                  id="message"
                  type="text"
                  required={true}
                  inputProps={{
                    style: { color: "white" },
                    onChange: handleChange(),
                    value: values[curFlag] ? values[curFlag].message : ""
                  }}
                />
                <InputField
                  fullWidth={false}
                  label="Send"
                  variant="outlined"
                  margin="dense"
                  size="medium"
                  className="inputContact"
                  id="send"
                  type="text"
                  required={true}
                  inputProps={{
                    style: { color: "white" },
                    onChange: handleChange(),
                    value: values[curFlag] ? values[curFlag].send : ""
                  }}
                />
              </Box>
              <Box
                component="div"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "1rem"
                }}
              >
                <InputField
                  fullWidth={false}
                  label="Projects"
                  variant="outlined"
                  margin="dense"
                  size="medium"
                  className="inputContact"
                  id="projects"
                  type="text"
                  required={true}
                  inputProps={{
                    style: { color: "white" },
                    onChange: handleChange(),
                    value: values[curFlag] ? values[curFlag].projects : ""
                  }}
                />
                <InputField
                  fullWidth={false}
                  label="Back"
                  variant="outlined"
                  margin="dense"
                  size="medium"
                  className="inputContact"
                  id="back"
                  type="text"
                  required={true}
                  inputProps={{
                    style: { color: "white" },
                    onChange: handleChange(),
                    value: values[curFlag] ? values[curFlag].back : ""
                  }}
                />
                <InputField
                  fullWidth={false}
                  label="Thanx"
                  variant="outlined"
                  margin="dense"
                  size="medium"
                  className="inputContact"
                  id="thanx"
                  type="text"
                  required={true}
                  inputProps={{
                    style: { color: "white" },
                    onChange: handleChange(),
                    value: values[curFlag] ? values[curFlag].thanx : ""
                  }}
                />
              </Box>
              <div style={{ display: "flex", gap: "2rem" }}>
                <Button
                  className={classes.button}
                  style={{ color: "white", borderColor: "white" }}
                  variant="outlined"
                  fullWidth={true}
                  startIcon={<ReplyIcon />}
                  component={Link}
                  to="/admin"
                >
                  Retour
                </Button>
                <Button
                  className={`${classes.button} clickContact`}
                  variant="outlined"
                  fullWidth={true}
                  endIcon={<SaveIcon />}
                  type="submit"
                >
                  Enregistrer
                </Button>
              </div>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default Configuration;
