import React from "react";
import "./project.css";
import Techno from "./Techno";
import Technos from "./Technos";
import { Grid } from "@material-ui/core";
import CManu from "../../assets/projects/cmanu.png";
import Planitia from "../../assets/projects/planitia.png";
import Covid19 from "../../assets/projects/covid19.png";
import Passcare from "../../assets/projects/passcare.png";
import eVigne from "../../assets/projects/evigne.jpg";
import Certiweb from "../../assets/projects/certiweb.jpg";
import LanguageIcon from "@material-ui/icons/Language";

const renderImg = name => {
  switch (name) {
    case "Cyberfolio":
      return CManu;
    case "Planitia":
      return Planitia;
    case "Covid19":
      return Covid19;
    case "Passcare":
      return Passcare;
    case "eVigne":
      return eVigne;
    case "Certiweb":
      return Certiweb;
    default:
      return CManu;
  }
};

const project = ({ project }) => {
  return (
    <React.Fragment key={project.order}>
      <Grid
        item
        xs={12}
        sm={6}
        md={3}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div className="game">
          <div className="rank">{project.order}</div>
          <div className="front">
            {/* <img className="thumbnail" src={CManu} alt="" /> */}
            <img className="thumbnail" src={renderImg(project.title)} alt="" />
            <h3 className="name icon">{project.title}</h3>
            <div className="resume">
              <p className="main-info icon">
                <LanguageIcon /> {project.api}
              </p>
              <Technos technos={project.technos} />
            </div>
          </div>

          <div className="back">
            <a
              href={project.link}
              target="_blank"
              className="go-wrap"
              rel="noopener noreferrer"
            >
              <div className="go">&#9658;</div>
            </a>
            <div className="components-info">
              <p className="component">
                {project.header1}
                <span>{project.content1}</span>
              </p>
              <p className="component">
                {project.header2}
                <span>{project.content2}</span>
              </p>
            </div>
            <div className="technos">
              {project.technos.map((techno, index) => (
                <Techno key={index} name={techno} />
              ))}
            </div>
          </div>

          <div className="background">
            <img src={renderImg(project.title)} alt="" />
          </div>
        </div>
        <svg width="0" height="0" x="0px" y="0px">
          <defs>
            <clipPath id="wave" clipPathUnits="objectBoundingBox">
              <path
                d="M1.5,0H1H0.5H0v0.8C0.3,0.8,0.3,1,0.5,1c0,0,0,0,0,0C0.8,1,0.8,0.8,1,0.8c0,0,0,0,0,0C1.3,0.8,1.3,1,1.5,1
C1.8,1,1.8,0.8,2,0.8V0H1.5z"
              />
              <animateTransform
                attributeType="XML"
                attributeName="transform"
                type="translate"
                from="0 0"
                to="-200 0"
                begin="0s"
                dur="10s"
                repeatCount="indefinite"
              />
            </clipPath>
          </defs>
        </svg>
      </Grid>
    </React.Fragment>
  );
};

export default project;
