import React from "react";
const Pirate = () => {
  return (
    <iframe
      style={{ position: "relative", width: "100%", height: "100%" }}
      title="pirate"
      src="https://www.youtube.com/embed/i8ju_10NkGY"
      frameBorder="0"
      position="relative"
    ></iframe>
  );
};
export default Pirate;
