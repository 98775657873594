import React, { useEffect } from "react";
import { Typography, Box, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ReactHtmlParser from "react-html-parser";

const useStyles = makeStyles(theme => ({
  timeLineItem: {
    padding: "1rem",
    borderBottom: "2px solid tan",
    position: "relative",
    margin: "1rem 3rem 1rem 1rem",
    clear: "both",
    "&:after": {
      content: "''",
      position: "absolute"
    },
    "&:before": {
      content: "''",
      position: "absolute",
      right: "-0.625rem",
      top: "calc(50% - 5px)",
      borderStyle: "solid",
      borderColor: "tomato tomato transparent transparent",
      borderWidth: "0.625rem",
      transform: "rotate(45deg)"
    },
    [theme.breakpoints.up("md")]: {
      width: "44%",
      margin: "1rem",
      "&:nth-of-type(2n)": {
        float: "right",
        margin: "1rem",
        borderColor: "tan"
      },
      "&:nth-of-type(2n):before": {
        right: "auto",
        left: "-0.625rem",
        borderColor: "transparent transparent tomato tomato"
      }
    }
  },
  timeLineYear: {
    textAlign: "center",
    maxWidth: "max-content",
    margin: "0 3rem 0 auto",
    background: "transparent",
    color: "tan",
    lineHeight: 1,
    padding: "0.5rem 0 0.5rem",
    "&:before": {
      display: "none"
    },
    [theme.breakpoints.up("md")]: {
      background: "transparent",
      textAlign: "center",
      margin: "0 auto",
      "&:nth-of-type(2n)": {
        float: "none",
        margin: "0 auto"
      },
      "&nth-of-type(2n):before": {
        display: "none"
      }
    }
  },
  cache: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block"
    }
  },
  subHeading: {
    color: "white",
    padding: "0",
    textTransform: "uppercase"
  }
}));

const Experience = ({ experience, prev }) => {
  let lastPeriod = prev ? prev.period : "null";
  const classes = useStyles();

  let debounce_timer;

  const handleCacheColor = () => {
    if (debounce_timer) {
      window.clearTimeout(debounce_timer);
    }
    const caches = document.querySelectorAll(".toto");
    debounce_timer = window.setTimeout(() => {
      caches.forEach(cache => {
        if (window.innerWidth > 950) {
          cache.style.display = "block";
          let top = parseInt(cache.getBoundingClientRect().top);
          if (top < 360) {
            cache.style.backgroundColor = "#10001a";
          } else if (top < 460) {
            cache.style.backgroundColor = "#1b0021";
          } else {
            cache.style.backgroundColor = "#2f0037";
          }
        } else {
          cache.style.display = "none";
        }
      });
    }, 50);
  };
  window.addEventListener("scroll", handleCacheColor);

  useEffect(() => {
    handleCacheColor();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {lastPeriod !== experience.period && (
        <Typography
          className={`${classes.timeLineYear} ${classes.timeLineItem}`}
          variant="h3"
          onClick={handleCacheColor}
        >
          <div
            style={{
              width: "maxContent",
              left: "50%",
              height: "100%",
              position: "relative",
              transform: "translateX(-50%)",
              zIndex: "2"
            }}
          >
            {experience.period}
          </div>
          <div
            className={`{classes.cache} toto`}
            style={{
              width: "4px",
              height: "100%",
              background: "#10001a",
              left: "50%",
              position: "absolute",
              top: "0",
              transform: "translateX(-50%)",
              zIndex: "1"
            }}
          ></div>
        </Typography>
      )}

      <Box component="div" className={classes.timeLineItem}>
        <Typography variant="h5" align="center" className={classes.subHeading}>
          {experience.function}
        </Typography>
        <Typography variant="h6" align="center" style={{ color: "tomato" }}>
          <Link
            target="_blank"
            color="inherit"
            rel="noopener"
            href={experience.link}
          >
            {experience.company}
          </Link>
        </Typography>
        <Typography
          variant="subtitle1"
          align="left"
          style={{ color: "tan", whiteSpace: "pre-line" }}
        >
          {ReactHtmlParser(experience.desc)}
        </Typography>
      </Box>
    </>
  );
};

export default Experience;
