import React from "react";
import { TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const InputField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "tomato"
    },
    "& label": {
      color: "tan"
    },
    "& .MuiInputBase-root": {
      color: "tan"
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "tan"
      },
      "&:hover fieldset": {
        borderColor: "tan"
      },
      "&.Mui-focused fieldset": {
        borderColor: "tomato"
      }
    }
  }
})(TextField);

export default function Input(props) {
  const { label, name, value, error = null, onChange, ...other } = props;
  return (
    <InputField
      variant="outlined"
      size="small"
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      inputProps={{
        style: { color: "white" }
      }}
      {...other}
      {...(error && { error: true, helperText: error })}
    />
  );
}
