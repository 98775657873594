import React, { useCallback, useContext, useEffect, useState } from "react";
import useTable from "./components/useTable";
import { trimR } from "../../tools/strings";
import {
  Box,
  Button,
  InputAdornment,
  makeStyles,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Typography
} from "@material-ui/core";
import Controls from "./controls/Controls";
import { GlobalContext } from "../../context/GlobalState";
import { Link, useHistory } from "react-router-dom";
import Particules from "../effects/Particules";
import Navbar from "../Navbar";
import { Search } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import ReplyIcon from "@material-ui/icons/Reply";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CloseIcon from "@material-ui/icons/Close";
import ProjectForm from "./ProjectForm";
import Popup from "./components/Popup";
import Notification from "./components/Notification";
import ConfirmDialog from "./components/ConfirmDialog";

const useStyles = makeStyles(theme => ({
  mainContainer: {
    height: "100%"
  },
  opaci: {
    opacity: "0.5"
  },
  table: {
    minWidth: 1200
  },
  form: {
    maxWidth: "1400px",
    position: "relative",
    padding: "1rem",
    margin: "0 auto"
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
    position: "relative",
    backgroundColor: "#ffffff0f"
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between"
  },
  button: {
    margin: "1rem",
    color: "tomato",
    borderColor: "tomato",
    lineHeight: "1.2rem",
    alignItems: "self-end"
  }
}));

const Projects = () => {
  let history = useHistory();
  const classes = useStyles();
  const {
    user,
    loading,
    error,
    setErrorNull,
    projects,
    getProjects,
    postProject,
    putProject,
    deleteProject
  } = useContext(GlobalContext);

  const [recordForEdit, setRecordForEdit] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: ""
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: ""
  });

  useEffect(() => {
    if (user.userinfo === undefined) {
      history.push("/");
      // console.log(user);
    }
    getProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [notifyMsg, setNotifyMsg] = useState("");

  useEffect(() => {
    // console.log("EffERR", error);
    if (error.value === null) return;

    if (error.value) {
      setNotify({
        isOpen: true,
        message: error.msg,
        type: "error"
      });
    } else {
      setNotify({
        isOpen: true,
        message: notifyMsg,
        type: "success"
      });
    }
    setErrorNull();
  }, [error, setErrorNull, notifyMsg]);

  const searchInput = document.querySelector("#search");

  const [filterFn, setFilterFn] = useState({
    fn: items => {
      return items;
    }
  });

  const handleSearch = useCallback(() => {
    let target = searchInput;
    if (target === null) return;

    setFilterFn({
      fn: items => {
        return items.filter(x => x.title.toLowerCase().includes(target.value));
      }
    });
  }, [setFilterFn, searchInput]);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  const headCells = [
    { id: "order", label: "Order" },
    { id: "title", label: "Titles" },
    { id: "api", label: "Api" },
    { id: "header1", label: "Header1" },
    { id: "header2", label: "Header2" },
    { id: "content1", label: "Content1" },
    { id: "content2", label: "Content2" },
    { id: "link", label: "Links", disableSorting: true },
    { id: "actions", label: "Actions", disableSorting: true }
  ];

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting
  } = useTable(projects, headCells, filterFn);

  const addOrEdit = async (project, resetForm) => {
    if (project._id === 0) {
      delete project._id;
      setNotifyMsg("Project Added");
      await postProject(project);
    } else {
      setNotifyMsg("Project Updated");
      await putProject(project);
    }
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
    getProjects();
  };

  const onDelete = async id => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false
    });
    setNotifyMsg("Project deleted");
    await deleteProject(id).then(getProjects);
  };

  const openInPopup = project => {
    setRecordForEdit(project);
    setOpenPopup(true);
  };

  return (
    <>
      <Navbar />
      <Particules />
      {(loading && (
        <Box
          component="div"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Typography
            gutterBottom
            variant="h3"
            style={{ color: "white", position: "relative" }}
          >
            Loading...
          </Typography>
        </Box>
      )) || (
        <>
          <Box component="header" className={classes.mainContainer}>
            <Box
              component="div"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Typography
                gutterBottom
                variant="h3"
                style={{ color: "white", position: "relative" }}
              >
                ADMIN/Projects
              </Typography>
            </Box>
            <Paper className={classes.pageContent}>
              <Toolbar className={classes.toolbar}>
                <Controls.Input
                  id="search"
                  label="Search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    )
                  }}
                  onChange={handleSearch}
                />
                <Button
                  className={`${classes.button}`}
                  variant="outlined"
                  endIcon={<AddIcon />}
                  type="submit"
                  onClick={() => {
                    setOpenPopup(true);
                    setRecordForEdit(null);
                  }}
                >
                  Add Project
                </Button>
                <Button
                  className={classes.button}
                  style={{ color: "white", borderColor: "white", width: "20%" }}
                  variant="outlined"
                  fullWidth={true}
                  startIcon={<ReplyIcon />}
                  component={Link}
                  to="/admin"
                >
                  Retour
                </Button>
              </Toolbar>
              <TblContainer>
                <TblHead />
                <TableBody>
                  {recordsAfterPagingAndSorting().map(project => (
                    <TableRow key={project._id}>
                      <TableCell>{project.order}</TableCell>
                      <TableCell style={{ width: "120px" }}>
                        {project.title}
                      </TableCell>
                      <TableCell>{project.api}</TableCell>
                      <TableCell>{project.header1}</TableCell>
                      <TableCell>{project.header2}</TableCell>
                      <TableCell>{project.content1}</TableCell>
                      <TableCell>{project.content2}</TableCell>
                      <TableCell>{trimR(project.link, 20)}</TableCell>
                      <TableCell>
                        <Controls.ActionButton color="primary">
                          <EditOutlinedIcon
                            fontSize="small"
                            onClick={() => {
                              openInPopup(project);
                            }}
                          />
                        </Controls.ActionButton>
                        <Controls.ActionButton color="secondary">
                          <CloseIcon
                            fontSize="small"
                            onClick={() => {
                              setConfirmDialog({
                                isOpen: true,
                                title: "Are you sure to delete this record ?",
                                subTitle: "Irreversible",
                                onConfirm: () => {
                                  onDelete(project._id);
                                }
                              });
                            }}
                          />
                        </Controls.ActionButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </TblContainer>
              <TblPagination />
            </Paper>
            <Popup
              openPopup={openPopup}
              setOpenPopup={setOpenPopup}
              title="Project Form"
            >
              <ProjectForm
                recordForEdit={recordForEdit}
                addOrEdit={addOrEdit}
              />
            </Popup>
            <Notification notify={notify} setNotify={setNotify} />
            <ConfirmDialog
              confirmDialog={confirmDialog}
              setConfirmDialog={setConfirmDialog}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default Projects;
