import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import "./App.css";
import {
  createMuiTheme,
  responsiveFontSizes,
  MuiThemeProvider
} from "@material-ui/core";

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

theme.typography.subtitle1 = {
  // fontFamily: "Lobster Two",
  fontFamily: "Courgette",
  // fontFamily: "Orbitron",
  fontWeight: "400",
  fontSize: "0.850rem",
  "@media (min-width:600px)": {
    fontSize: "0.9rem"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.1rem"
  }
};

theme.typography.h3 = {
  fontFamily: "Orbitron",
  // fontFamily: "Courgette",
  // fontFamily: "Lobster Two",
  fontWeight: "700",
  fontSize: "1.7rem",
  "@media (min-width:600px)": {
    fontSize: "2rem"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2rem"
    // fontSize: "3rem"
  }
};

ReactDOM.render(
  <Router>
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </Router>,
  document.getElementById("root")
);
