import React, { useEffect } from "react";
import { Box, Grid } from "@material-ui/core";
import Controls from "./controls/Controls";
import { useForm, Form } from "./components/useForm";

const initialFValues = {
  _id: 0,
  flag: 0,
  order: 1,
  type: "",
  title: "",
  desc: "",
  link: "",
  visible: false
};

const flagItems = [
  { id: 0, title: "Fr" },
  { id: 1, title: "Gb" },
  { id: 2, title: "Jp" }
];

const SkillForm = ({ addOrEdit, recordForEdit, flag }) => {
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm
  } = useForm(initialFValues);

  useEffect(() => {
    if (recordForEdit !== null) {
      setValues({ ...recordForEdit });
    } else {
      setValues({ ...values, flag });
    }
    console.log("recordforedit", recordForEdit);
    console.log("UseEffectValue", values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordForEdit]);

  const validate = () => {
    let temp = {};
    temp.flag = "";
    temp.order = values.order ? "" : "Order required";
    temp.type = values.type ? "" : "Type required";
    temp.title = values.title ? "" : "Title required";
    temp.desc = values.desc ? "" : "Description required";
    temp.link = "";
    temp.visible = "";
    setErrors({
      ...temp
    });
    return Object.values(temp).every(x => x === "");
  };

  const handleSubmit = async e => {
    e.preventDefault();

    console.log("Values", values);
    if (validate()) {
      try {
        addOrEdit(values, resetForm);
      } catch (err) {
        console.log(err);
      }
    } else {
      console.log("validateError", errors);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12} style={{ display: "flex" }}>
          <Controls.RadioGroup
            name="flag"
            label=""
            value={values.flag}
            onChange={handleInputChange}
            items={flagItems}
          />
          <Controls.Checkbox
            name="visible"
            label="Visible"
            value={values.visible}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={6}>
          <Box component="div" style={{ display: "flex" }}>
            <Controls.Input
              label="Order"
              name="order"
              value={values.order}
              onChange={handleInputChange}
              error={errors.order}
            />
            <Controls.Input
              label="Type"
              name="type"
              value={values.type}
              onChange={handleInputChange}
              error={errors.type}
            />
          </Box>
          <Box component="div" style={{ display: "flex" }}>
            <Controls.Input
              label="Title"
              name="title"
              value={values.title}
              onChange={handleInputChange}
              error={errors.title}
            />
          </Box>
          <Box component="div" style={{ display: "flex" }}>
            <Controls.Input
              label="Link"
              name="link"
              value={values.link}
              onChange={handleInputChange}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Controls.Input
            label="Description"
            name="desc"
            value={values.desc}
            onChange={handleInputChange}
            multiline
            rows={7}
            fullWidth={true}
            error={errors.desc}
          />
          <Box
            component="div"
            style={{ display: "flex", justifyContent: "end" }}
          >
            <Controls.Button
              variant="contained"
              color="primary"
              size="small"
              text="Submit"
              type="submit"
            />
            <Controls.Button
              variant="contained"
              color="default"
              size="small"
              text="Reset"
              onClick={resetForm}
            />
          </Box>
        </Grid>
      </Grid>
    </Form>
  );
};

export default SkillForm;
