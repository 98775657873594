import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { GlobalContext } from "../context/GlobalState";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Pirate from "./Pirate";
import { TextField, Typography, Button, Box } from "@material-ui/core";
import { Animate } from "react-simple-animate";
import ReplyIcon from "@material-ui/icons/Reply";
import SendIcon from "@material-ui/icons/Send";

const useStyles = makeStyles(theme => ({
  mainContainer: {
    position: "absolute",
    background: "transparent",
    height: "100%",
    width: "100%",
    padding: "2rem .5rem 2rem 1.5rem"
  },
  form: {
    display: "flex",
    height: "60%",
    width: "85%",
    alignItems: "center",
    maxWidth: "400px",
    position: "relative",
    padding: "1rem",
    margin: "0 auto",
    "@media (max-width:600px)": {
      padding: "0.5rem"
    }
  },
  button: {
    marginTop: "1rem",
    color: "tomato",
    borderColor: "tomato",
    lineHeight: "1.2rem",
    "@media (max-width:1280px)": {
      fontSize: "0.8rem"
    },
    "@media (max-width:360px)": {
      transform: "translateX(-25px)"
    }
  },
  heading: {
    color: "tomato",
    padding: "2rem 0 1rem 0",
    textTransform: "uppercase",
    transform: "rotateZ(1.5deg)",
    "@media (max-width:600px)": {
      padding: "0.5rem 0",
      transform: "rotateZ(2.5deg)"
    }
  },
  avatar: {
    width: theme.spacing(17),
    height: theme.spacing(17),
    margin: theme.spacing(1)
  },
  connectInput: {
    color: "white",
    fontSize: "1rem",
    "@media (max-width:1280px)": {
      fontSize: "0.8rem"
    },
    "@media (max-width:360px)": {
      fontSize: "0.7rem"
    }
  }
}));

const InputField = withStyles({
  root: {
    transform: "rotateZ(3deg)",
    "& label.Mui-focused": {
      color: "tomato"
    },
    "& label": {
      color: "tan"
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "2px solid tan",
        borderColor: "tan"
      },
      "&:hover fieldset": {
        borderColor: "tan"
      },
      "&.Mui-focused fieldset": {
        borderColor: "tomato"
      }
    },
    "@media (max-width:1280px)": {
      "& .MuiFormLabel-root": {
        fontSize: "0.8rem"
      },
      "@media (max-width:360px)": {
        transform: "rotateZ(3deg) translateX(-25px)"
      }
    }
  }
})(TextField);

const Connect = ({ showForm, setShowForm, fail, setFail }) => {
  const { flag, configs } = useContext(GlobalContext);
  const classes = useStyles();

  const { setUser } = useContext(GlobalContext);

  const [values, setValues] = useState({
    email: "",
    password: ""
  });
  //const [fail, setFail] = useState(false);

  const handleChange = name => e => {
    setValues({ ...values, [e.target.id]: e.target.value });
  };

  const isFormValid = () => {
    if (!values.email || !values.password) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    isFormValid();
    try {
      const res = await axios.post("/api/v1/users/login", values);
      console.log(res);
      localStorage.setItem("auth-kobura", res.data.token);
      setUser();
      setShowForm(false);
    } catch (err) {
      setFail(true);
      console.log(err);
    }
  };
  // Inputs block effect
  const [move, setMove] = useState(false);
  useEffect(() => {
    setMove(true);
  }, [move]);
  // title effect
  const [title, setTitle] = useState(false);
  useEffect(() => {
    setTitle(true);
  }, [title]);

  return (
    <>
      {!fail ? (
        <Box component="header" className={classes.mainContainer}>
          <Animate
            play={title}
            duration={1}
            delay={0.5}
            start={{
              transform: "translateY(-100px)",
              opacity: "0"
            }}
            end={{ transform: "translateY(0px)", opacity: "1" }}
          >
            <Typography
              id="title"
              variant="h3"
              align="center"
              className={classes.heading}
            >
              {configs[flag] ? configs[flag].connection : ""}
            </Typography>
          </Animate>
          <Box
            component="form"
            className={classes.form}
            onSubmit={e => handleSubmit(e)}
          >
            <Animate
              play={move}
              duration={1}
              delay={1.5}
              start={{
                opacity: "0"
              }}
              end={{ opacity: "1" }}
            >
              <InputField
                fullWidth={true}
                label="Email"
                variant="outlined"
                margin="dense"
                // className="inputConnect"
                id="email"
                required={true}
                inputProps={{
                  // style: { color: "white" },
                  className: classes.connectInput,
                  size: "small",
                  autoComplete: "off",
                  required: true,
                  onChange: handleChange(),
                  value: values.email,
                  type: "text"
                }}
              />
              <InputField
                fullWidth={true}
                label="Password"
                variant="outlined"
                margin="dense"
                size="small"
                className="inputConnect"
                id="password"
                type="password"
                required={true}
                onChange={handleChange()}
                inputProps={{
                  className: classes.connectInput,
                  value: values.password
                }}
              />
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  transform: "rotateZ(3.2deg)"
                }}
              >
                <Button
                  className={classes.button}
                  style={{ color: "white", borderColor: "white" }}
                  variant="outlined"
                  fullWidth={true}
                  startIcon={<ReplyIcon />}
                  onClick={() => setShowForm(false)}
                >
                  {configs[flag] ? configs[flag].back : ""}
                </Button>
                <Button
                  className={`${classes.button} clickContact`}
                  variant="outlined"
                  fullWidth={true}
                  endIcon={<SendIcon />}
                  type="submit"
                >
                  {configs[flag] ? configs[flag].connection : ""}
                </Button>
              </div>
            </Animate>
          </Box>
        </Box>
      ) : (
        <Pirate />
      )}
    </>
  );
};

export default Connect;
