import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import ReplyIcon from "@material-ui/icons/Reply";

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: "1rem",
    color: "white",
    borderColor: "white",
    lineHeight: "1.2rem",
    alignItems: "self-end",
    position: "fixed",
    right: "7%",
    top: "40%",
    width: "5%",
    zIndex: "1"
  }
}));
const NotFound = () => {
  const classes = useStyles();
  return (
    <>
      {/* <div
        style={{
          position: "fixed",
          right: "100px",
          top: "100px",
          zIndex: "1"
        }}
      > */}
      <Button
        className={classes.button}
        variant="outlined"
        fullWidth={true}
        startIcon={<ReplyIcon />}
        component={Link}
        to="/"
      ></Button>
      {/* </div> */}
      <iframe
        style={{ position: "absolute", width: "100%", height: "100%" }}
        title="pirate"
        src="https://www.youtube.com/embed/cXGFg3sm_1M?autoplay=1&mute=1"
        frameborder="0"
        position="absolute"
      ></iframe>
    </>
  );
};
export default NotFound;
