import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box } from "@material-ui/core";
import Particules from "./effects/Particules";
import { Animate } from "react-simple-animate";
import Experience from "./experiences/Experience";
import BtnToTop from "./effects/BtnToTop";
import Navbar from "./Navbar";
import { GlobalContext } from "../context/GlobalState";

const useStyles = makeStyles(theme => ({
  overlayCanvaDiv: {
    position: "relative",
    backgroundColor: "black",
    height: "100%"
  },
  mainContainer: {
    // background: "#291e36",
    height: "100%",
    paddingBottom: "250px"
  },
  timeLine: {
    position: "relative",
    padding: "1rem",
    margin: "0 auto",
    "&:before": {
      content: "''",
      position: "absolute",
      height: "97%",
      border: "1px solid tan",
      right: "40px",
      top: 0
    },
    "&:after": {
      content: "''",
      display: "table",
      clear: "both"
    },
    [theme.breakpoints.up("md")]: {
      padding: "2rem",
      "&:before": {
        left: "calc(50% - 1px)",
        right: "auto"
      }
    }
  },
  heading: {
    color: "tomato",
    padding: "3rem 0",
    textTransform: "uppercase"
  }
}));

const Resume = () => {
  const classes = useStyles();

  const { flag, configs, resumes, getResumes } = useContext(GlobalContext);
  useEffect(() => {
    getResumes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [move, setMove] = useState(false);
  useEffect(() => {
    setMove(true);
  }, [move]);

  // console.log("cfgFlag", configs[flag]);
  // Experiences Array
  const displayExp = resumes
    .filter(function (resume) {
      return resume.flag === flag && resume.visible;
    })
    .map((resume, index, array) => (
      <Experience
        key={resume._id}
        experience={resume}
        prev={array[index - 1]}
      />
    ));

  return (
    <>
      <Navbar />
      <Particules />
      <Box component="header" className={classes.mainContainer}>
        <Animate
          play={move}
          duration={1}
          delay={0.1}
          start={{
            transform: "translateY(-100px)",
            opacity: "0"
          }}
          end={{ transform: "translateY(0px)", opacity: "1" }}
        >
          <Typography variant="h3" align="center" className={classes.heading}>
            {configs[flag] ? configs[flag].resume : ""}
          </Typography>
        </Animate>
        <Animate
          play={move}
          duration={1}
          delay={1.5}
          start={{
            opacity: "0"
          }}
          end={{ opacity: "1" }}
        >
          <Box component="div" className={classes.timeLine}>
            {displayExp}
          </Box>
        </Animate>
        <BtnToTop />
      </Box>
    </>
  );
};

export default Resume;
