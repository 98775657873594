import React from "react";
import LinkIcon from "@material-ui/icons/Link";
import LinkOffIcon from "@material-ui/icons/LinkOff";
import personal from "../../assets/img/personal.jpg";
import cardFooter from "../../assets/img/dark-dot.png";
import {
  BottomNavigation,
  BottomNavigationAction,
  Grid,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(theme => ({
  cardContainer: {
    margin: "4rem auto",
    maxWidth: 320,
    minWidht: 100,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 290
    }
  },
  cardContent: {
    overflowY: "auto",
    background: "whitesmoke",
    height: "255px"
  },
  cardFooter: {
    background: "#222",
    backgroundImage: `url(${cardFooter})`,
    "& .MuiBottomNavigation": {
      minWidth: 0,
      maxWidth: 30
    },
    "& .MuiBottomNavigationAction-wrapper": {
      justifyContent: "center",
      width: "auto"
    },
    "& .MuiSvgIcon-root": {
      justifyContent: "center",
      fill: "tan",
      "&:hover": {
        fill: "tomato",
        fontSize: "1.8rem"
      }
    }
  },
  overlay: {
    position: "absolute",
    color: "white",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: "22px",
    textTransform: "uppercase",
    // fontFamily: "Lobster Two",
    fontFamily: "Orbitron",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px"
    }
  }
}));

const Competence = ({ competence }) => {
  const classes = useStyle();
  return (
    <React.Fragment key={competence.id}>
      <Grid item xs={12} sm={6} md={4} style={{ zIndex: "1" }}>
        <Card className={classes.cardContainer}>
          <CardActionArea>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                alt="project1"
                height="100"
                image={personal}
              />
              <Typography variant="h4" className={classes.overlay}>
                {competence.type}
              </Typography>
            </div>
            <CardContent className={classes.cardContent}>
              <Typography gutterBottom variant="h5" style={{ color: "black" }}>
                {competence.title}
              </Typography>
              <Typography
                style={{
                  height: "180px",
                  whiteSpace: "pre-line"
                }}
                variant="body2"
                color="textSecondary"
                component="p"
              >
                {competence.desc}
              </Typography>
            </CardContent>
          </CardActionArea>
          <BottomNavigation
            className={classes.cardFooter}
            style={{
              justifyContent: "center",
              height: "40px"
            }}
          >
            {competence.link !== "" ? (
              <BottomNavigationAction
                style={{
                  padding: 0,
                  justifyContent: "center"
                }}
                icon={<LinkIcon />}
                href={competence.link}
                target="_blank"
              />
            ) : (
              <BottomNavigationAction
                style={{
                  padding: 0,
                  justifyContent: "center"
                }}
                icon={<LinkOffIcon />}
              />
            )}
          </BottomNavigation>
        </Card>
      </Grid>
    </React.Fragment>
  );
};

export default Competence;
