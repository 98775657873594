import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Particles from "./effects/Particules";
import Competence from "./cards/Competence";
import BtnToTop from "./effects/BtnToTop";
import Navbar from "./Navbar";
import { Animate } from "react-simple-animate";
import { Box, Grid, Typography } from "@material-ui/core";
import { GlobalContext } from "../context/GlobalState";

const useStyle = makeStyles({
  mainContainer: {
    // background: "#291e36",
    height: "100%"
  },
  heading: {
    color: "tomato",
    padding: "3rem 0 0 0",
    textTransform: "uppercase"
  }
});

const Portfolio = () => {
  const classes = useStyle();

  const { flag, configs, competences, getCompetences, loading } = useContext(
    GlobalContext
  );
  useEffect(() => {
    getCompetences();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!loading) {
    // console.log(competences);
  }

  const [move, setMove] = useState(false);

  useEffect(() => {
    setMove(true);
  }, [move]);

  const displayComp = competences
    .filter(function (competence) {
      return competence.flag === flag && competence.visible;
    })
    .map(competence => (
      <Competence key={competence._id} competence={competence} />
    ));

  return (
    <Box component="div" className={classes.mainContainer}>
      <Navbar />
      <Particles />
      <Animate
        play={move}
        duration={1}
        delay={0.1}
        start={{
          transform: "translateY(-100px)",
          opacity: "0"
        }}
        end={{ transform: "translateY(0px)", opacity: "1" }}
      >
        <Typography variant="h3" align="center" className={classes.heading}>
          {configs[flag] ? configs[flag].skills : ""}
        </Typography>
      </Animate>

      <Animate
        play={move}
        duration={1}
        delay={1.5}
        start={{
          opacity: "0"
        }}
        end={{ opacity: "1" }}
      >
        <Grid container justify="center">
          {displayComp}
        </Grid>
      </Animate>
      <BtnToTop />
    </Box>
  );
};

export default Portfolio;
