import React, { useCallback, useContext, useEffect, useState } from "react";
import useTable from "./components/useTable";
import { trimR } from "../../tools/strings";
import {
  Box,
  Button,
  InputAdornment,
  makeStyles,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Typography
} from "@material-ui/core";
import Controls from "./controls/Controls";
import { GlobalContext } from "../../context/GlobalState";
import { Link, useHistory } from "react-router-dom";
import Particules from "../effects/Particules";
import Navbar from "../Navbar";
import gbFlag from "../../Gb.png";
import frFlag from "../../Fr.png";
import jpFlag from "../../Jp.png";
import { Search } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import ReplyIcon from "@material-ui/icons/Reply";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CloseIcon from "@material-ui/icons/Close";
import ResumeForm from "./ResumeForm";
import Popup from "./components/Popup";
import Notification from "./components/Notification";
import ConfirmDialog from "./components/ConfirmDialog";

const useStyles = makeStyles(theme => ({
  mainContainer: {
    // background: "#291e36",
    height: "100%"
  },
  opaci: {
    opacity: "0.5"
  },
  table: {
    minWidth: 1200
  },
  form: {
    maxWidth: "1400px",
    position: "relative",
    padding: "1rem",
    margin: "0 auto"
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
    position: "relative",
    backgroundColor: "#ffffff0f"
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between"
  },
  button: {
    margin: "1rem",
    color: "tomato",
    borderColor: "tomato",
    lineHeight: "1.2rem",
    alignItems: "self-end"
  }

  // ".MuiTableCell-body": {
  //   color: "tan"
  // }
}));

const Resumes = () => {
  let history = useHistory();
  const classes = useStyles();
  const {
    user,
    loading,
    resumes,
    getResumes,
    postResume,
    putResume,
    deleteResume
  } = useContext(GlobalContext);

  const [curFlag, setCurFlag] = useState(0);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: ""
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: ""
  });

  useEffect(() => {
    if (user.userinfo === undefined) {
      history.push("/");
      console.log(user);
    }
    getResumes();
    console.log("getResumes", resumes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchInput = document.querySelector("#search");

  const handleFlag = e => {
    const btns = document.querySelectorAll(".btn-flag");
    btns.forEach(btn => {
      btn.classList.add(classes.opaci);
    });
    e.currentTarget.classList.remove(classes.opaci);
    setCurFlag(+e.currentTarget.value);
  };

  const [filterFn, setFilterFn] = useState({
    fn: items => {
      return items.filter(x => x.flag === curFlag);
    }
  });

  const handleSearch = useCallback(() => {
    let target = searchInput;
    if (target === null) return;

    setFilterFn({
      fn: items => {
        if (target.value === "") return items.filter(x => x.flag === curFlag);
        else
          return items.filter(
            x =>
              x.company.toLowerCase().includes(target.value) &&
              x.flag === curFlag
          );
      }
    });
  }, [curFlag, setFilterFn, searchInput]);

  useEffect(() => {
    handleSearch();
  }, [curFlag, handleSearch]);

  const headCells = [
    { id: "order", label: "Order" },
    { id: "period", label: "Periods" },
    { id: "company", label: "Companies" },
    { id: "function", label: "Functions" },
    { id: "link", label: "Links", disableSorting: true },
    { id: "actions", label: "Action", disableSorting: true }
  ];

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting
  } = useTable(resumes, headCells, filterFn);

  const addOrEdit = async (resume, resetForm) => {
    console.log("R", resume);
    if (resume._id === 0) {
      delete resume._id;
      await postResume(resume).then(getResumes);
    } else {
      await putResume(resume).then(getResumes);
    }

    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
    setNotify({
      isOpen: true,
      message: "Submitted Successfuly",
      type: "success"
    });
  };

  const onDelete = async id => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false
    });
    await deleteResume(id).then(getResumes);
    setNotify({
      isOpen: true,
      message: "Deleted Successfuly",
      type: "success"
    });
  };

  const openInPopup = resume => {
    setRecordForEdit(resume);
    setOpenPopup(true);
  };

  return (
    <>
      <Navbar />
      <Particules />
      {(loading && (
        <Box
          component="div"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Typography
            gutterBottom
            variant="h3"
            style={{ color: "white", position: "relative" }}
          >
            Loading...
          </Typography>
        </Box>
      )) || (
        <>
          <Box component="header" className={classes.mainContainer}>
            <Box
              component="div"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Typography
                gutterBottom
                variant="h3"
                style={{ color: "white", position: "relative" }}
              >
                ADMIN/Resumes
              </Typography>
            </Box>
            <Paper className={classes.pageContent}>
              <Toolbar className={classes.toolbar}>
                <Controls.Input
                  id="search"
                  label="Search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    )
                  }}
                  onChange={handleSearch}
                />
                {/* <Controls.Button
                  text="Add Resume"
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={() => setOpenPopup(true)}
                /> */}
                <Button
                  className={`${classes.button}`}
                  variant="outlined"
                  endIcon={<AddIcon />}
                  type="submit"
                  onClick={() => {
                    setOpenPopup(true);
                    setRecordForEdit(null);
                  }}
                >
                  Add Resume
                </Button>
                <Box component="div" style={{ display: "flex" }}>
                  <Button value={0} onClick={handleFlag} className="btn-flag">
                    <img src={frFlag} alt="fr"></img>
                  </Button>
                  <Button
                    value={1}
                    onClick={handleFlag}
                    className={`${classes.opaci} btn-flag`}
                  >
                    <img src={gbFlag} alt="gb"></img>
                  </Button>
                  <Button
                    value={2}
                    onClick={handleFlag}
                    className={`${classes.opaci} btn-flag`}
                  >
                    <img src={jpFlag} alt="jp"></img>
                  </Button>
                  <Button
                    className={classes.button}
                    style={{ color: "white", borderColor: "white" }}
                    variant="outlined"
                    fullWidth={true}
                    startIcon={<ReplyIcon />}
                    component={Link}
                    to="/admin"
                  >
                    Retour
                  </Button>
                </Box>
              </Toolbar>
              <TblContainer>
                <TblHead />
                <TableBody>
                  {recordsAfterPagingAndSorting().map(resume => (
                    <TableRow key={resume._id}>
                      <TableCell>{resume.order}</TableCell>
                      <TableCell style={{ width: "120px" }}>
                        {resume.period}
                      </TableCell>
                      <TableCell>{resume.company}</TableCell>
                      <TableCell>{resume.function}</TableCell>
                      <TableCell>{trimR(resume.link, 20)}</TableCell>
                      <TableCell>
                        <Controls.ActionButton color="primary">
                          <EditOutlinedIcon
                            fontSize="small"
                            onClick={() => {
                              openInPopup(resume);
                            }}
                          />
                        </Controls.ActionButton>
                        <Controls.ActionButton color="secondary">
                          <CloseIcon
                            fontSize="small"
                            onClick={() => {
                              setConfirmDialog({
                                isOpen: true,
                                title: "Are you sure to delete this record ?",
                                subTitle: "Irreversible",
                                onConfirm: () => {
                                  onDelete(resume._id);
                                }
                              });
                            }}
                          />
                        </Controls.ActionButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </TblContainer>
              <TblPagination />
            </Paper>
            <Popup
              openPopup={openPopup}
              setOpenPopup={setOpenPopup}
              title="Resume Form"
            >
              <ResumeForm
                recordForEdit={recordForEdit}
                addOrEdit={addOrEdit}
                flag={curFlag}
              />
            </Popup>
            <Notification notify={notify} setNotify={setNotify} />
            <ConfirmDialog
              confirmDialog={confirmDialog}
              setConfirmDialog={setConfirmDialog}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default Resumes;
