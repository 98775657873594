import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import "./App.css";
import Home from "./components/index";
import Resume from "./components/Resume";
import Portfolio from "./components/Portfolio";
import Projects from "./components/Projects";
import Contacts from "./components/Contacts";
import Admin from "./components/admin/Admin";
import Configuration from "./components/admin/Configuration";
import Resumes from "./components/admin/Resumes";
import Skills from "./components/admin/Skills";
import ProjectsAdmin from "./components/admin/Projects";
import NotFound from "./components/NotFound";
import ScrollToTop from "./components/effects/ScrollToTop";

import { GlobalProvider } from "./context/GlobalState";

function App() {
  return (
    <GlobalProvider>
      <CssBaseline />
      <BrowserRouter>
        <ScrollToTop>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/resume" component={Resume} />
            <Route path="/portfolio" component={Portfolio} />
            <Route path="/projects" component={Projects} />
            <Route path="/contacts" component={Contacts} />
            <Route exact path="/admin" component={Admin} />
            <Route path="/admin/configuration" component={Configuration} />
            <Route path="/admin/resumes" component={Resumes} />
            <Route path="/admin/skills" component={Skills} />
            <Route path="/admin/projects" component={ProjectsAdmin} />
            <Route path="*" component={NotFound} />
          </Switch>
        </ScrollToTop>
      </BrowserRouter>
    </GlobalProvider>
  );
}

export default App;
