import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "../context/GlobalState";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  TextField,
  Avatar,
  Typography,
  Button,
  Box,
  Grid
} from "@material-ui/core";
import { Animate } from "react-simple-animate";
import SendIcon from "@material-ui/icons/Send";
import Navbar from "./Navbar";
import Particules from "./effects/Particules";
import Notification from "./admin/components/Notification";
import Hearts from "./effects/Hearts";
import manu from "../manu3.png";

const useStyles = makeStyles(theme => ({
  main: {
    display: "flex",
    position: "absolute",
    width: "100%",
    height: "60%",
    alignItems: "center"
  },
  mainContainer: {
    height: "100%"
  },
  form: {
    maxWidth: "500px",
    position: "relative",
    padding: "1rem",
    margin: "0 auto"
  },
  button: {
    marginTop: "1rem",
    color: "tomato",
    borderColor: "tomato",
    lineHeight: "1.2rem"
  },
  heading: {
    color: "tomato",
    padding: "3rem 0",
    textTransform: "uppercase"
  },
  avatar: {
    width: theme.spacing(17),
    height: theme.spacing(17),
    margin: theme.spacing(1)
  }
}));

const InputField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "tomato"
    },
    "& label": {
      color: "tan"
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "tan"
      },
      "&:hover fieldset": {
        borderColor: "tan"
      },
      "&.Mui-focused fieldset": {
        borderColor: "tomato"
      }
    }
  }
})(TextField);

const Contacts = () => {
  const { flag, configs, postEmail, error, setErrorNull } = useContext(
    GlobalContext
  );
  const classes = useStyles();

  /****************** Send Email******************** */
  const [values, setValues] = useState({
    name: "",
    email: "",
    company: "",
    message: ""
  });

  const [phase, setPhase] = useState(0);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: ""
  });

  const handleChange = name => e => {
    setValues({ ...values, [e.target.id]: e.target.value });
  };

  const isFormValid = () => {
    if (!values.name || !values.message) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    // console.log("EffERR", error);
    if (error.value === null) return;

    if (error.value) {
      setNotify({
        isOpen: true,
        message: error.msg,
        type: "error"
      });
    } else {
      setNotify({
        isOpen: true,
        message: "Email sent",
        type: "success"
      });
      thanx();
    }
    setErrorNull();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, setErrorNull]);

  const sendEmail = async () => {
    await postEmail(values);
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (!isFormValid()) {
      //message of error in the screen, maybe sweet alerts
      // console.log("Form invalid");
    } else {
      // console.log(values);
      sendEmail(e);
    }
  };

  /*********************** Thanx effects ************************* */
  // Inputs block effect
  const [move, setMove] = useState(false);
  useEffect(() => {
    setMove(true);
  }, [move]);
  // title effect
  const [title, setTitle] = useState(false);
  useEffect(() => {
    setTitle(true);
  }, [title]);
  const [show, setShow] = useState(false);

  let history = useHistory();

  function thanx() {
    setShow(false);
    // fade send button
    setTimeout(function () {
      const clickContact = document.querySelector(".clickContact");
      clickContact.classList.add("clickFade");
      clickContact.addEventListener("transitionend", function () {
        clickContact.remove();
      });
    }, 500);
    // Collapse input fields
    const inputContacts = document.querySelectorAll(".inputContact");
    const revContacts = [].slice.call(inputContacts, 0).reverse();
    revContacts.forEach(function (input, index) {
      setTimeout(
        function () {
          let first = true;
          input.classList.add("fall");
          input.addEventListener("transitionend", function () {
            input.remove();
            if (index === 3 && first) {
              const container = document.querySelector("#contAvatar");
              first = false;
              setShow(true);
              container.style.display = "flex";
            }
          });
        },
        [index * 500]
      );
    });
    // Change title to thanks
    const titleDiv = document.querySelector("#title");
    setTitle(false);
    setTimeout(function () {
      titleDiv.innerText = configs[flag] ? configs[flag].thanx : "";
      setTitle(true);
    }, 3000);

    setTimeout(
      function () {
        setPhase(1);
      },
      [5000]
    );

    setTimeout(
      function () {
        history.push("/");
      },
      [10000]
    );
  }

  return (
    <>
      <Navbar />
      {phase === 0 ? <Particules /> : <Hearts />}
      <Box component="header" className={classes.mainContainer}>
        <Animate
          play={title}
          duration={1}
          delay={0.5}
          start={{
            transform: "translateY(-100px)",
            opacity: "0"
          }}
          end={{ transform: "translateY(0px)", opacity: "1" }}
        >
          <Typography
            id="title"
            variant="h3"
            align="center"
            className={classes.heading}
          >
            {configs[flag] ? configs[flag].contactme : ""}
          </Typography>
        </Animate>
      </Box>
      <div className={classes.main}>
        <Box
          component="form"
          className={classes.form}
          onSubmit={e => handleSubmit(e)}
        >
          <Animate
            play={move}
            duration={1}
            delay={1.5}
            start={{
              opacity: "0"
            }}
            end={{ opacity: "1" }}
          >
            <InputField
              fullWidth={true}
              label={configs[flag] ? configs[flag].name : ""}
              variant="outlined"
              margin="dense"
              size="medium"
              className="inputContact"
              id="name"
              type="text"
              required={true}
              inputProps={{
                style: { color: "white" },
                onChange: handleChange(),
                value: values.name
              }}
            />
            <InputField
              fullWidth={true}
              label={configs[flag] ? configs[flag].email : ""}
              variant="outlined"
              margin="dense"
              size="medium"
              className="inputContact"
              id="email"
              type="email"
              required={true}
              onChange={handleChange()}
              inputProps={{
                style: { color: "white" },
                value: values.email
              }}
            />
            <InputField
              fullWidth={true}
              label={configs[flag] ? configs[flag].company : ""}
              variant="outlined"
              margin="dense"
              size="medium"
              className="inputContact"
              id="company"
              type="text"
              required={false}
              onChange={handleChange()}
              formcontrolprops={{
                required: false,
                fullWidth: true
              }}
              inputProps={{
                style: { color: "white" },
                required: false,
                onChange: handleChange(),
                id: "company",
                value: values.company,
                type: "text"
              }}
            />
            <InputField
              fullWidth={true}
              label={configs[flag] ? configs[flag].message : ""}
              multiline
              rows={5}
              variant="outlined"
              margin="dense"
              size="medium"
              className="inputContact"
              id="message"
              required={true}
              formcontrolprops={{
                size: "medium",
                rows: "5",
                required: true,
                fullWidth: true
              }}
              inputProps={{
                style: { color: "white", whiteSpace: "pre-line" },
                required: true,
                onChange: handleChange(),
                id: "message",
                value: values.message,
                type: "text",
                autoComplete: "off"
              }}
            />
            <Button
              className={`${classes.button} clickContact`}
              variant="outlined"
              fullWidth={true}
              endIcon={<SendIcon />}
              type="submit"
            >
              {configs[flag] ? configs[flag].send : ""}
            </Button>
          </Animate>
          <Grid
            container
            id="contAvatar"
            justify="center"
            style={{ display: "none" }}
          >
            <Animate
              play={show}
              duration={1.4}
              delay={1}
              start={{
                opacity: "0"
              }}
              end={{ opacity: "1" }}
            >
              <Avatar
                id="avatar"
                className={classes.avatar}
                src={manu}
                alt="Emmanuel Torreguitart"
              />
            </Animate>
          </Grid>
        </Box>
      </div>
      <Notification notify={notify} setNotify={setNotify} />
      {/* </Box> */}
    </>
  );
};

export default Contacts;
