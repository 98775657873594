import React, { useEffect } from "react";
import { Box, Grid } from "@material-ui/core";
import Controls from "./controls/Controls";
import { useForm, Form } from "./components/useForm";

const initialFValues = {
  _id: 0,
  order: 1,
  title: "",
  api: "",
  technos: "",
  header1: "",
  header2: "",
  content1: "",
  content2: "",
  link: "",
  visible: false
};

const ProjectForm = ({ addOrEdit, recordForEdit }) => {
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm
  } = useForm(initialFValues);

  useEffect(() => {
    if (recordForEdit !== null) {
      setValues({ ...recordForEdit });
    }

    // console.log("recordforedit", recordForEdit);
    // console.log("UseEffectValue", values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordForEdit]);

  const validate = () => {
    let temp = {};
    temp.order = values.order ? "" : "Order required";
    temp.title = values.title ? "" : "Title required";
    temp.api = "";
    temp.technos = values.technos ? "" : "Technos required";
    temp.header1 = values.header1 ? "" : "Header1 required";
    temp.header2 = values.header2 ? "" : "Header2 required";
    temp.content1 = values.content1 ? "" : "Content1 required";
    temp.content2 = values.content2 ? "" : "Content2 required";
    temp.link = "";
    temp.visible = "";
    setErrors({
      ...temp
    });
    return Object.values(temp).every(x => x === "");
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (typeof values.technos === "string")
      values.technos = values.technos.split(",");

    // console.log("Values", values);
    if (validate()) {
      try {
        addOrEdit(values, resetForm);
      } catch (err) {
        // console.log(err);
      }
    } else {
      // console.log("validateError", errors);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12} style={{ display: "flex" }}>
          <Controls.Checkbox
            name="visible"
            label="Visible"
            value={values.visible}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={6}>
          <Box component="div" style={{ display: "flex" }}>
            <Controls.Input
              label="Order"
              name="order"
              value={values.order}
              onChange={handleInputChange}
              error={errors.order}
            />
            <Controls.Input
              label="Title"
              name="title"
              value={values.title}
              onChange={handleInputChange}
              error={errors.title}
            />
          </Box>
          <Box component="div" style={{ display: "flex" }}>
            <Controls.Input
              label="API"
              name="api"
              value={values.api}
              onChange={handleInputChange}
              error={errors.api}
            />
          </Box>
          <Box component="div" style={{ display: "flex" }}>
            <Controls.Input
              label="Link"
              name="link"
              value={values.link}
              onChange={handleInputChange}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box component="div" style={{ display: "flex" }}>
            <Controls.Input
              label="Technos"
              name="technos"
              value={values.technos}
              onChange={handleInputChange}
              error={errors.technos}
            />
          </Box>
          <Box component="div" style={{ display: "flex" }}>
            <Controls.Input
              label="Header1"
              name="header1"
              value={values.header1}
              onChange={handleInputChange}
              error={errors.header1}
            />
            <Controls.Input
              label="Content1"
              name="content1"
              value={values.content1}
              onChange={handleInputChange}
              error={errors.content1}
            />
          </Box>
          <Box component="div" style={{ display: "flex" }}>
            <Controls.Input
              label="Header2"
              name="header2"
              value={values.header2}
              onChange={handleInputChange}
              error={errors.header2}
            />
            <Controls.Input
              label="Content2"
              name="content2"
              value={values.content2}
              onChange={handleInputChange}
              error={errors.content2}
            />
          </Box>
          <Box
            component="div"
            style={{ display: "flex", justifyContent: "end" }}
          >
            <Controls.Button
              variant="contained"
              color="primary"
              size="small"
              text="Submit"
              type="submit"
            />
            <Controls.Button
              variant="contained"
              color="default"
              size="small"
              text="Reset"
              onClick={resetForm}
            />
          </Box>
        </Grid>
      </Grid>
    </Form>
  );
};

export default ProjectForm;
