import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroup as MuiRadioGroup
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiTypography-root": {
      color: "tan"
    }
  },
  radio: {
    "& .MuiRadio-root": {
      color: "tan"
    }
  },
  label: {
    "& .MuiFormLabel-root": {
      color: "white"
    }
  }
}));

function RadioGroup(props) {
  const { name, label, value, onChange, items } = props;
  const classes = useStyles();
  console.log("Rvalue", value);
  return (
    <FormControl>
      <FormLabel className={classes.label}>{label}</FormLabel>
      <MuiRadioGroup row name={name} value={+value} onChange={onChange}>
        {items.map(item => (
          <FormControlLabel
            className={`${classes.root} ${classes.radio}`}
            key={item.id}
            value={item.id}
            control={<Radio />}
            label={item.title}
          />
        ))}
      </MuiRadioGroup>
    </FormControl>
  );
}

export default RadioGroup;
