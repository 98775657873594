import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import mask from "../assets/img/dark-dot.png";
import MobilRightMenuSlider from "@material-ui/core/Drawer";
import { GlobalContext } from "../context/GlobalState";
import {
  AppBar,
  Toolbar,
  ListItem,
  IconButton,
  ListItemText,
  Avatar,
  Divider,
  List,
  Typography,
  Box,
  ListItemIcon,
  Button
} from "@material-ui/core";

import {
  MenuRounded,
  AssignmentInd,
  Home,
  Apps,
  BlurOn,
  ContactMail,
  Https,
  AccountBox
} from "@material-ui/icons";

import avatar from "../manu3.png";
import gbFlag from "../Gb.png";
import frFlag from "../Fr.png";
import jpFlag from "../Jp.png";
import Footer from "./Footer";

// CSS style
const useStyles = makeStyles(theme => ({
  offset: theme.mixins.toolbar,
  rightMenu: {
    // height: "100vh"
  },
  menuSliderContainer: {
    display: "flex",
    flexDirection: "column",
    width: 250,
    background: "#291e36",
    backgroundImage: `url(${mask})`,
    height: "100%"
  },
  avatar: {
    display: "block",
    margin: "0.5rem auto",
    width: theme.spacing(13),
    height: theme.spacing(13)
  },
  flag: {
    display: "block",
    height: "24px",
    width: "24px"
  },
  listItem: {
    color: "#ce93d8",
    "&:hover": {
      color: "tan"
    },
    "& > span": {
      fontFamily: "Orbitron"
    }
  },
  list: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    "& > a:nth-child(6)": {
      marginTop: "auto"
    }
  }
}));

const Navbar = ({ showForm, setShowForm }) => {
  const [state, setState] = useState({
    right: false
  });

  const currentLocation = window.location.pathname;

  // Context
  const {
    loading,
    configs,
    getConfigs,
    flag,
    changeFlag,
    user,
    setUser
  } = useContext(GlobalContext);

  useEffect(() => {
    setUser();
    getConfigs();
    // console.log("UseEffect", configs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let menuItems = [];
  if (!loading && configs[flag] !== undefined) {
    menuItems = [
      {
        listIcon: <Home />,
        listText: configs[flag].home,
        listPath: "/"
      },
      {
        listIcon: <AssignmentInd />,
        listText: configs[flag].resume,
        listPath: "/resume"
      },
      {
        listIcon: <Apps />,
        listText: configs[flag].skills,
        listPath: "/portfolio"
      },
      {
        listIcon: <BlurOn />,
        listText: configs[flag].projects,
        listPath: "/projects"
      },
      {
        listIcon: <ContactMail />,
        listText: configs[flag].contact,
        listPath: "/contacts"
      }
    ];
  }
  const toggleSlider = (slider, open) => () =>
    setState({ ...state, [slider]: open });

  const classes = useStyles();

  const handleShowForm = () => {
    setState({ ...state, right: false });
    setShowForm(!showForm);
  };
  const handleDisconnect = () => {
    localStorage.setItem("auth-kobura", "");
    setUser();
  };

  const sideList = slider => (
    <Box
      className={classes.menuSliderContainer}
      component="div"
      onClick={() => toggleSlider(slider, false)}
    >
      <Avatar
        className={classes.avatar}
        src={avatar}
        alt="Emmanuel Torreguitart"
      />
      <Divider />
      <List className={classes.list}>
        {menuItems.map((lsItem, key) => (
          <ListItem button key={key} component={Link} to={lsItem.listPath}>
            <ListItemIcon className={classes.listItem}>
              {lsItem.listIcon}
            </ListItemIcon>
            <ListItemText
              className={classes.listItem}
              primary={lsItem.listText}
            />
          </ListItem>
        ))}
        {user.userinfo !== undefined && (
          <ListItem button component={Link} to="/admin">
            <ListItemIcon className={classes.listItem}>
              <AccountBox />
            </ListItemIcon>
            <ListItemText className={classes.listItem} primary="Admin" />
          </ListItem>
        )}
        <ListItem
          button
          component="a"
          onClick={() => {
            user.userinfo === undefined ? handleShowForm() : handleDisconnect();
          }}
        >
          {user.userinfo !== undefined ? (
            <>
              <ListItemIcon className={classes.listItem}>
                <Https />
              </ListItemIcon>
              <ListItemText
                className={classes.listItem}
                primary="Déconnexion"
              />
            </>
          ) : (
            currentLocation === "/" && (
              <>
                <ListItemIcon className={classes.listItem}>
                  <Https />
                </ListItemIcon>
                <ListItemText
                  className={classes.listItem}
                  primary={configs[flag] ? configs[flag].connection : ""}
                />
              </>
            )
          )}
        </ListItem>
      </List>
    </Box>
  );
  return (
    !loading &&
    configs[flag] !== undefined && (
      <>
        {/* {console.log("conf", configs[flag].title)} */}
        <Box component="nav">
          <AppBar
            position="fixed"
            style={{ background: "#222", backgroundImage: `url(${mask})` }}
          >
            <Toolbar style={{ justifyContent: "space-between" }}>
              <IconButton
                edge="start"
                onClick={toggleSlider("right", true)}
                style={{ alignItems: "center" }}
              >
                <MenuRounded style={{ color: "tomato" }} />
                <Typography
                  variant="h5"
                  style={{
                    fontFamily: "Orbitron",
                    color: "tan",
                    marginLeft: "1rem"
                  }}
                >
                  {configs[flag].title}
                </Typography>
              </IconButton>
              <MobilRightMenuSlider
                className={classes.rightMenu}
                anchor="right"
                open={state.right}
                onClose={toggleSlider("right", false)}
              >
                {sideList("right")}
                <Footer />
              </MobilRightMenuSlider>
              <Box component="div" style={{ display: "flex" }}>
                {user.userinfo !== undefined && (
                  <Typography
                    variant="h5"
                    style={{
                      color: "white",
                      marginRight: "1rem",
                      alignSelf: "center",
                      fontSize: "1.2rem"
                    }}
                  >
                    {user.userinfo.displayName}
                  </Typography>
                )}
                <Button>
                  <img
                    className={classes.flag}
                    src={flag === 0 ? gbFlag : flag === 1 ? jpFlag : frFlag}
                    alt="flag"
                    onClick={changeFlag}
                  ></img>
                </Button>
              </Box>
            </Toolbar>
          </AppBar>
          <div className={classes.offset} />
        </Box>
      </>
    )
  );
};

export default Navbar;
