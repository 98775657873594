import React, { useState } from "react";
import {
  makeStyles,
  Table,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  table: {
    marginTop: theme.spacing(1),
    "& thead th": {
      fontWeight: "600",
      color: "tan",
      backgroundColor: "#280030"
    },
    "& thead th span:hover": {
      color: "white"
    },
    "& tbody td": {
      fontWeight: "300",
      backgroundColor: "#c7b1ca",
      color: "black",
      borderBottom: "1px solid rgb(40, 0, 48)"
    },
    "& tbody tr:hover": {
      backgroundColor: "#280030",
      cursor: "pointer",
      color: "white"
    }
  },
  pagination: {
    backgroundColor: "#280030",
    color: "white"
  },
  caption: {
    color: "tan",
    padding: 8
    // border: "1px dashed grey",
    // fontSize: "0.875rem"
  },
  toolbar: {
    "& > p:nth-of-type(2)": {
      // fontSize: "1.25rem",
      color: "white"
      // fontWeight: 600
    }
  }
}));

export default function useTable(records, headCells, filterFn) {
  const classes = useStyles();

  const pages = [10, 15, 25];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pages[page]);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();

  const TblContainer = props => (
    <Table className={classes.table} size="small">
      {props.children}
    </Table>
  );

  const TblHead = props => {
    const handleSortRequest = cellId => {
      const isAsc = orderBy === cellId && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(cellId);
      // console.log(order, orderBy);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map(headCell => (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.disableSorting ? (
                headCell.label
              ) : (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={() => {
                    handleSortRequest(headCell.id);
                  }}
                >
                  {headCell.label}
                </TableSortLabel>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangRowsPerPage = e => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  const TblPagination = () => (
    <TablePagination
      className={classes.pagination}
      classes={{
        toolbar: classes.toolbar,
        caption: classes.caption
      }}
      component="div"
      page={page}
      rowsPerPageOptions={pages}
      rowsPerPage={rowsPerPage}
      count={records.length}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangRowsPerPage}
    />
  );

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function getComparator(order, orderBy) {
    console.log(order, orderBy);
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const recordsAfterPagingAndSorting = () => {
    return stableSort(
      filterFn.fn(records),
      getComparator(order, orderBy)
    ).slice(page * rowsPerPage, (page + 1) * rowsPerPage);
  };

  return {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting
  };
}
