import React from "react";
import ParticlesBg from "particles-bg";
import { makeStyles } from "@material-ui/core/styles";
// import icon from "../../assets/img/heart";
import icon from "../../assets/img/tux.png";
import mask from "../../assets/img/space.jpg";

const useStyles = makeStyles({
  particlesCanva: {
    position: "absolute",
    height: "100%"
  },
  div: {
    position: "fixed",
    left: 0,
    top: 0,
    height: "100%",
    width: "100%",
    background: `url(${mask}) no-repeat center center fixed;`,
    backgroundSize: "cover"
  }
});

const Hearts = () => {
  const classes = useStyles();

  let config = {
    num: [4, 7],
    rps: 0.4,
    radius: [5, 40],
    life: [1.5, 3],
    v: [2, 3],
    tha: [-50, 50],
    alpha: [0.6, 0],
    scale: [0.1, 0.9],
    body: icon,
    position: "center",
    // position: {x:1,y:1,width:100,height:100},
    //color: ["random", "#ff0000"],
    cross: "dead",
    random: 10
  };
  return (
    <>
      <div className={classes.div}>
        <ParticlesBg
          type="custom"
          bg={false}
          config={config}
          className={classes.particlesCanva}
        />
      </div>
    </>
  );
};

export default Hearts;
