import React from "react";
import MongoIcon from "../../assets/projects/mongo.png";
import ExpressIcon from "../../assets/projects/express.png";
import ReactIcon from "../../assets/projects/react.png";
import NodeIcon from "../../assets/projects/node.png";
import Html5Icon from "../../assets/projects/html5.png";
import Css3Icon from "../../assets/projects/css3.png";
import BootstrapIcon from "../../assets/projects/bootstrap.png";
import MySQLIcon from "../../assets/projects/mysql.png";
import CakeIcon from "../../assets/projects/cake.png";
import MaterialUiIcon from "../../assets/projects/material_ui.png";
import VueIcon from "../../assets/projects/vue.png";
import jQuery from "../../assets/projects/jquery.png";
import Tailwind from "../../assets/projects/tailwind.png";
import JavascriptIcon from "../../assets/projects/js.png";

const Techno = ({ name }) => {
  return (
    <div className="techno">
      <div className="icon">
        {
          {
            Mongo: <img src={MongoIcon} alt="MongoDb" />,
            Express: <img src={ExpressIcon} alt="Express" />,
            React: <img src={ReactIcon} alt="React" />,
            Node: <img src={NodeIcon} alt="Node" />,
            HTML5: <img src={Html5Icon} alt="Html5" />,
            CSS3: <img src={Css3Icon} alt="Css3" />,
            Javascript: <img src={JavascriptIcon} alt="Js" />,
            MySQL: <img src={MySQLIcon} alt="MySQL" />,
            Bootstrap: <img src={BootstrapIcon} alt="Bootstrap" />,
            CakePHP: <img src={CakeIcon} alt="CakePHP" />,
            Vue: <img src={VueIcon} alt="Vue" />,
            jQuery: <img src={jQuery} alt="jQuery" />,
            Tailwind: <img src={Tailwind} alt="Tailwind" />,
            "Material-UI": <img src={MaterialUiIcon} alt="Material-ui" />
          }[name]
        }
      </div>
      <p className="name">{name}</p>
    </div>
  );
};

export default Techno;
