import React from "react";
import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 0,
    marginRight: theme.spacing(0.5),
    padding: theme.spacing(0.3)
  },
  secondary: {
    backgroundColor: "tan",
    "& .MuiButton-label": {
      color: "black"
    }
  },
  primary: {
    backgroundColor: theme.palette.primary.light,
    "& .MuiButton-label": {
      color: theme.palette.primary.main
    }
  }
}));

export default function ActionButton(props) {
  const { color, children, onClick } = props;

  const classes = useStyles();

  return (
    <Button className={`${classes.root} ${classes[color]}`} onClick={onClick}>
      {children}
    </Button>
  );
}
