import React, { useEffect, useState, useContext } from "react";
import { Typography, Avatar, Grid, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Animate } from "react-simple-animate";
import manu from "../manu3.png";
import manu1 from "../manueye1.png";
import manu2 from "../manueye2.png";
import Typed from "react-typed";
import { GlobalContext } from "../context/GlobalState";

// CSS Styles
const useStyles = makeStyles(theme => ({
  avatar: {
    width: theme.spacing(17),
    height: theme.spacing(17),
    margin: theme.spacing(1),
    transform: "rotateZ(4deg)",
    opacity: 1,
    [theme.breakpoints.down("xs")]: {
      width: theme.spacing(15),
      height: theme.spacing(15),
      margin: theme.spacing(0.5)
    }
  },
  title: {
    width: "100%",
    color: "tomato",
    fontSize: "1.6rem",
    // fontFamily: "Courgette",
    fontFamily: "Orbitron",
    // fontFamily: "Lobster Two",
    transform: "rotateZ(4deg)",
    "@media (max-width:600px)": {
      fontSize: "1.1rem"
    }
  },
  subtitle: {
    width: "100%",
    color: "tan",
    transform: "rotateZ(4deg)",
    "@media (max-width:600px)": {
      fontSize: "1rem"
    }
  },
  typedContainer: {
    position: "absolute",
    top: "53%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    textAlign: "center",
    zIndex: 1
  }
}));

const Header = () => {
  // Context
  const { flag, configs, loading } = useContext(GlobalContext);

  let fullName = "Emmanuel Torreguitart";
  let qualities = ["..."];

  if (!loading && configs[flag] !== undefined) {
    qualities = configs[flag].qualities;
    fullName = configs[flag].fullname;
    // console.log("configs ", configs[flag].flag);
  }
  // const typeRef = useRef(null);

  //State Avatar
  const [curAvatar, setCurAvatar] = useState(0);
  const [delai, setDelai] = useState(5000);
  const [path, setPath] = useState(0);

  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(true);
  }, [show]);

  const classes = useStyles();

  // Effet Clin d'oeil
  useEffect(() => {
    const avatar = document.querySelector("#avatar");
    const id = setInterval(() => {
      switch (curAvatar) {
        case 0:
          avatar.childNodes[0].attributes[1].nodeValue = manu;
          setCurAvatar(1);
          setDelai(80);
          break;
        case 1:
          avatar.childNodes[0].attributes[1].nodeValue = manu1;
          setCurAvatar(2);
          setDelai(80);
          break;
        case 2:
          avatar.childNodes[0].attributes[1].nodeValue = manu2;
          setCurAvatar(0);
          if (path === 0) {
            setPath(1);
            setDelai(80);
          } else {
            setPath(0);
            setDelai(10000);
          }
          break;
        default:
          avatar.childNodes[0].attributes[1].nodeValue = manu;
          setCurAvatar(0);
      }
    }, delai);
    return () => clearInterval(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curAvatar]);

  return (
    <Box className={classes.typedContainer}>
      <Grid container justify="center">
        <Animate
          play={show}
          duration={1.4}
          delay={0.1}
          start={{
            opacity: "0"
          }}
          end={{ opacity: "1" }}
        >
          <Avatar
            id="avatar"
            className={classes.avatar}
            src={manu}
            alt="Emmanuel Torreguitart"
          />
        </Animate>
        <Avatar
          id="avatar"
          className={classes.avatar}
          src={manu1}
          alt="Emmanuel Torreguitart"
          style={{ display: "none" }}
        />
        <Avatar
          id="avatar"
          className={classes.avatar}
          src={manu2}
          alt="Emmanuel Torreguitart"
          style={{ display: "none" }}
        />
      </Grid>
      <Typography variant="h4" className={classes.title}>
        {fullName}
        {/* <Typed strings={[`${fullName}`]} typeSpeed={80} showCursor={false} /> */}
      </Typography>
      <br />
      {configs[flag] !== undefined && (
        <Typography variant="h5" className={classes.subtitle}>
          {(configs[flag].flag === "jp" && (
            <Typed strings={qualities} typeSpeed={100} backSpeed={60} loop />
          )) || (
            <Typed strings={qualities} typeSpeed={70} backSpeed={40} loop />
          )}
        </Typography>
      )}
    </Box>
  );
};

export default Header;
