export default (state, action) => {
  switch (action.type) {
    /* USER */
    case "SET_USER":
      return {
        ...state,
        user: action.payload
      };

    /* CONFIGS */
    case "GET_CONFIGS":
      return {
        ...state,
        loading: false,
        configs: action.payload
      };
    case "CONFIGS_ERROR":
      return {
        ...state,
        error: action.payload
      };

    /* COMPETENCES */
    case "GET_COMPETENCES":
      return {
        ...state,
        loading: false,
        competences: action.payload
      };
    case "POST_COMPETENCE_REQUEST":
      return {
        ...state,
        loading: true
      };
    case "POST_COMPETENCE":
      return {
        ...state,
        loading: false
      };
    case "POST_COMPETENCE_ERROR":
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case "PUT_COMPETENCE":
      return {
        ...state,
        loading: false
      };
    case "DELETE_COMPETENCE":
      return {
        ...state,
        loading: false
      };
    case "DELETE_COMPETENCE_ERROR":
      return {
        ...state,
        error: action.payload
      };
    case "COMPETENCES_ERROR":
      return {
        ...state,
        error: action.payload
      };

    /* RESUMES */
    case "GET_RESUMES":
      return {
        ...state,
        loading: false,
        resumes: action.payload
      };
    case "POST_RESUME":
      return {
        ...state,
        loading: false
      };
    case "PUT_RESUME":
      return {
        ...state,
        loading: false
      };
    case "DELETE_RESUME":
      return {
        ...state
      };
    case "DELETE_RESUME_ERROR":
      return {
        ...state,
        error: action.payload
      };
    case "RESUMES_ERROR":
      return {
        ...state,
        error: action.payload
      };

    /* PROJECTS */
    case "GET_PROJECTS":
      return {
        ...state,
        loading: false,
        projects: action.payload
      };
    case "PROJECT_ERROR":
      return {
        ...state,
        error: {
          value: true,
          msg: action.payload
        }
      };
    case "POST_PROJECT":
      return {
        ...state,
        error: false,
        loading: false
      };
    case "PUT_PROJECT":
      return {
        ...state,
        error: {
          value: false,
          msg: ""
        },
        loading: false
      };
    case "DELETE_PROJECT":
      return {
        ...state,
        error: {
          value: false,
          msg: ""
        },
        loading: false
      };
    case "POST_EMAIL":
      return {
        ...state,
        error: {
          value: false,
          msg: ""
        },
        loading: false
      };
    case "EMAIL_ERROR":
      return {
        ...state,
        error: {
          value: true,
          msg: action.payload
        }
      };

    /* Error To Null*/
    case "SET_ERROR_NULL":
      return {
        ...state,
        error: {
          value: null,
          msg: null
        }
      };

    /* FLAG */
    case "CHANGE_FLAG":
      if (action.payload === 2) {
        action.payload = 0;
      } else {
        action.payload++;
      }
      return {
        ...state,
        flag: action.payload
      };

    case "LOADING_TRUE":
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};
