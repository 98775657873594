import React from "react";
import Particles from "react-particles-js";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import mask from "../../assets/img/space.jpg";

const useStyles = makeStyles({
  particlesCanva: {
    position: "fixed"
  },
  overlayCanva: {
    position: "fixed",
    backgroundColor: "black",
    background: `url(${mask}) no-repeat center center;`,
    backgroundSize: "cover",
    opacity: "1",
    width: "100vw",
    height: "100vh",
    zIndex: "-5"
  }
});

const Particules = () => {
  const classes = useStyles();
  return (
    <>
      <Box
        id="canvas"
        component="canvas"
        className={classes.overlayCanva}
      ></Box>
      <Particles
        height="100%"
        canvasClassName={classes.particlesCanva}
        params={{
          particles: {
            number: {
              value: 8,
              density: {
                enable: false,
                value_area: 700
              }
            },
            line_linked: {
              enable: false,
              opacity: 0.01
            },
            // shape: {
            //   type: "star",
            //   stroke: {
            //     width: 1,
            //     color: "tomato"
            //   }
            // },
            move: {
              // direction: "right",
              speed: 0.5
            },
            // size: {
            //   value: 2,
            //   random: true,
            //   anim: {
            //     enable: true,
            //     speed: 0.4,
            //     size_min: 0.3,
            //     sync: false
            //   }
            // },
            opacity: {
              value: 0.9,
              random: true
              // anim: {
              //   enable: true,
              //   speed: 0.5,
              //   opacity_min: 0.3,
              //   sync: false
              // }
            }
          }
        }}

        // params={{
        //   particles: {
        //     number: {
        //       value: 25,
        //       density: {
        //         enable: true,
        //         value_area: 1500
        //       }
        //     },
        //     line_linked: {
        //       enable: false,
        //       opacity: 0.02
        //     },
        //     move: {
        //       // direction: "top",
        //       speed: 0.1
        //     },
        //     size: {
        //       value: 1.5
        //     },
        //     opacity: {
        //       anim: {
        //         enable: true,
        //         speed: 1,
        //         opacity_min: 0.05
        //       }
        //     }
        //   }
        //   // interactivity: {
        //   //   events: {
        //   //     onclick: {
        //   //       enable: true,
        //   //       mode: "push"
        //   //     }
        //   //   },
        //   //   modes: {
        //   //     push: {
        //   //       particles_nb: 1
        //   //     }
        //   //   }
        //   // },
        //   // retina_detect: true
        // }}
      />
    </>
  );
};

export default Particules;
