import React from "react";
import MongoIcon from "../../assets/projects/mongo.png";
import ExpressIcon from "../../assets/projects/express.png";
import ReactIcon from "../../assets/projects/react.png";
import NodeIcon from "../../assets/projects/node.png";
import Html5Icon from "../../assets/projects/html5.png";
import Css3Icon from "../../assets/projects/css3.png";
import CakeIcon from "../../assets/projects/cake.png";
import MySQLIcon from "../../assets/projects/mysql.png";
import BootstrapIcon from "../../assets/projects/bootstrap.png";
import JavascriptIcon from "../../assets/projects/javascript.png";
import VueIcon from "../../assets/projects/vue.png";
import jQuery from "../../assets/projects/jquery.png";
import Tailwind from "../../assets/projects/tailwind.png";
import MaterialUiIcon from "../../assets/projects/material_ui.png";

const Technos = ({ technos }) => {
  return (
    <div className={`technos-${technos.length}`}>
      {technos.map(
        (techno, index) =>
          // techno === "Mongo" ? (
          //   <img src={MongoIcon} alt="" />
          // ) : techno === "Express" ? (
          //   <img src={ExpressIcon} alt="" />
          // ) : techno === "React" ? (
          //   <img src={ReactIcon} alt="" />
          // ) : techno === "Node" ? (
          //   <img src={NodeIcon} alt="" />
          // ) : (
          //   <img src={Html5Icon} alt="" />
          // )
          ({
            Mongo: <img src={MongoIcon} alt="MongoDb" key={index} />,
            Express: <img src={ExpressIcon} alt="Express" key={index} />,
            React: <img src={ReactIcon} alt="React" key={index} />,
            Node: <img src={NodeIcon} alt="Node" key={index} />,
            HTML5: <img src={Html5Icon} alt="Html5" key={index} />,
            CSS3: <img src={Css3Icon} alt="Css3" key={index} />,
            CakePHP: <img src={CakeIcon} alt="CakePHP" key={index} />,
            MySQL: <img src={MySQLIcon} alt="MySQL" key={index} />,
            Bootstrap: <img src={BootstrapIcon} alt="Bootstrap" key={index} />,
            Vue: <img src={VueIcon} alt="Vue" />,
            Tailwind: <img src={Tailwind} alt="Tailwind" />,
            jQuery: <img src={jQuery} alt="jQuery" />,
            Javascript: <img src={JavascriptIcon} alt="Js" key={index} />,
            "Material-UI": (
              <img src={MaterialUiIcon} alt="Material-ui" key={index} />
            )
          }[techno])
      )}
    </div>
  );
};

export default Technos;
