import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "./cards/project.css";
import Project from "./cards/Project";
import Particles from "./effects/Particules";
import BtnToTop from "./effects/BtnToTop";
import Navbar from "./Navbar";
import { Animate } from "react-simple-animate";
import { Box, Grid, Typography } from "@material-ui/core";
import { GlobalContext } from "../context/GlobalState";

const useStyles = makeStyles(theme => ({
  mainContainer: {
    height: "100%"
  },
  heading: {
    color: "tomato",
    padding: "3rem 0 0 0",
    textTransform: "uppercase"
  },
  gap: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      height: "3200px",
      display: "block"
    }
  }
}));

const Projects = () => {
  const classes = useStyles();

  const { flag, configs, projects, getProjects, loading } = useContext(
    GlobalContext
  );
  useEffect(() => {
    getProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!loading) {
    // console.log(projects);
  }

  const [move, setMove] = useState(false);

  useEffect(() => {
    setMove(true);
  }, [move]);

  // const projects = [
  //   {
  //     order: 10,
  //     title: "Cyberfolio",
  //     api: "Hubblesite",
  //     technos: ["Mongo", "Express", "React", "Node"],
  //     header1: "Style",
  //     header2: "API",
  //     content1: "Material-ui",
  //     content2: "Hubblesite",
  //     link: "https://manu.kobura.fr"
  //   },
  //   {
  //     order: 11,
  //     title: "Planitia",
  //     api: "Hubblesite",
  //     technos: ["HTML5", "CSS3", "Javascript"],
  //     header1: "Style",
  //     header2: "API",
  //     content1: "Material-ui",
  //     content2: "Hubblesite",
  //     link: "https://manu.kobura.fr"
  //   },
  //   {
  //     order: 12,
  //     title: "Cyberfolio",
  //     api: "Hubblesite",
  //     technos: ["Mongo", "Express"],
  //     header1: "Style",
  //     header2: "API",
  //     content1: "Material-ui",
  //     content2: "Hubblesite",
  //     link: "https://manu.kobura.fr"
  //   },
  //   {
  //     order: 13,
  //     title: "Cyberfolio",
  //     api: "Hubblesite",
  //     technos: ["Mongo"],
  //     header1: "Style",
  //     header2: "API",
  //     content1: "Material-ui",
  //     content2: "Hubblesite",
  //     link: "https://manu.kobura.fr"
  //   }
  // ];

  const displayProj = projects
    .filter(function (project) {
      return project.visible;
    })
    .map(project => <Project key={project.order} project={project} />);

  return (
    <Box component="div" className={classes.mainContainer}>
      <Navbar />
      <Particles />
      <Animate
        play={move}
        duration={1}
        delay={0.1}
        start={{
          transform: "translateY(-100px)",
          opacity: "0"
        }}
        end={{ transform: "translateY(0px)", opacity: "1" }}
      >
        <Typography variant="h3" align="center" className={classes.heading}>
          {configs[flag] ? configs[flag].projects : ""}
        </Typography>
      </Animate>

      <Animate
        play={move}
        duration={1}
        delay={1.5}
        start={{
          opacity: "0"
        }}
        end={{ opacity: "1" }}
      >
        <Grid
          container
          justify="center"
          style={{ marginTop: "8rem", rowGap: "10rem" }}
        >
          {displayProj}
        </Grid>

        <div className={classes.gap}></div>
      </Animate>
      <BtnToTop />
    </Box>
  );
};

export default Projects;
